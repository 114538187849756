/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddFleetManagerApiV1CompaniesCompanyIdFleetsPostData,
  AddFleetManagerApiV1CompaniesCompanyIdFleetsPostError,
  AddUserApiV1CompaniesCompanyIdUsersPostData,
  AddUserApiV1CompaniesCompanyIdUsersPostError,
  AgreeToTosApiV1UsersUserIdTosAgreedPostData,
  AgreeToTosApiV1UsersUserIdTosAgreedPostError,
  AgreeToTosSelfApiV1UsersSelfTosAgreedPostData,
  GetNotificationApiV1NotificationsNotificationIdGetData,
  GetNotificationApiV1NotificationsNotificationIdGetError,
  GetUserApiV1UsersUserIdGetData,
  GetUserApiV1UsersUserIdGetError,
  GetUserSelfApiV1UsersSelfGetData,
  ListFleetManagersByCompanyApiV1CompaniesCompanyIdFleetsGetData,
  ListFleetManagersByCompanyApiV1CompaniesCompanyIdFleetsGetError,
  ListFleetManagersByCompanyApiV1CompaniesCompanyIdFleetsGetParams,
  ListNotificationsByUserApiV1UsersSelfNotificationsGetData,
  ListNotificationsByUserApiV1UsersSelfNotificationsGetError,
  ListNotificationsByUserApiV1UsersSelfNotificationsGetParams,
  ListUsersByCompanyApiV1CompaniesCompanyIdUsersGetData,
  ListUsersByCompanyApiV1CompaniesCompanyIdUsersGetError,
  ListUsersByCompanyApiV1CompaniesCompanyIdUsersGetParams,
  MarkNotificationAsReadApiV1NotificationsNotificationIdReadPostData,
  MarkNotificationAsReadApiV1NotificationsNotificationIdReadPostError,
  UpdateUserApiV1UsersUserIdPatchData,
  UpdateUserApiV1UsersUserIdPatchError,
  UpdateUserSelfApiV1UsersSelfPatchData,
  UpdateUserSelfApiV1UsersSelfPatchError,
  UserCreate,
  UserCreateWithType,
  UserUpdate,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class User<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
undefined
 *
undefined
 */
  getUserSelfApiV1UsersSelfGet = (params: RequestParams = {}) =>
    this.http.request<GetUserSelfApiV1UsersSelfGetData, any>({
      path: `/api/v1/users/self/`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  updateUserSelfApiV1UsersSelfPatch = (data: UserUpdate, params: RequestParams = {}) =>
    this.http.request<UpdateUserSelfApiV1UsersSelfPatchData, UpdateUserSelfApiV1UsersSelfPatchError>({
      path: `/api/v1/users/self/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  getUserApiV1UsersUserIdGet = (userId: string, params: RequestParams = {}) =>
    this.http.request<GetUserApiV1UsersUserIdGetData, GetUserApiV1UsersUserIdGetError>({
      path: `/api/v1/users/${userId}/`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  updateUserApiV1UsersUserIdPatch = (userId: string, data: UserUpdate, params: RequestParams = {}) =>
    this.http.request<UpdateUserApiV1UsersUserIdPatchData, UpdateUserApiV1UsersUserIdPatchError>({
      path: `/api/v1/users/${userId}/`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  listNotificationsByUserApiV1UsersSelfNotificationsGet = (
    query: ListNotificationsByUserApiV1UsersSelfNotificationsGetParams,
    params: RequestParams = {}
  ) =>
    this.http.request<
      ListNotificationsByUserApiV1UsersSelfNotificationsGetData,
      ListNotificationsByUserApiV1UsersSelfNotificationsGetError
    >({
      path: `/api/v1/users/self/notifications/`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  getNotificationApiV1NotificationsNotificationIdGet = (notificationId: string, params: RequestParams = {}) =>
    this.http.request<
      GetNotificationApiV1NotificationsNotificationIdGetData,
      GetNotificationApiV1NotificationsNotificationIdGetError
    >({
      path: `/api/v1/notifications/${notificationId}/`,
      method: 'GET',
      ...params,
    });
  /**
undefined
 *
undefined
 */
  markNotificationAsReadApiV1NotificationsNotificationIdReadPost = (
    notificationId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MarkNotificationAsReadApiV1NotificationsNotificationIdReadPostData,
      MarkNotificationAsReadApiV1NotificationsNotificationIdReadPostError
    >({
      path: `/api/v1/notifications/${notificationId}/read/`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  listUsersByCompanyApiV1CompaniesCompanyIdUsersGet = (
    { companyId, ...query }: ListUsersByCompanyApiV1CompaniesCompanyIdUsersGetParams,
    params: RequestParams = {}
  ) =>
    this.http.request<
      ListUsersByCompanyApiV1CompaniesCompanyIdUsersGetData,
      ListUsersByCompanyApiV1CompaniesCompanyIdUsersGetError
    >({
      path: `/api/v1/companies/${companyId}/users/`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  addUserApiV1CompaniesCompanyIdUsersPost = (companyId: any, data: UserCreateWithType, params: RequestParams = {}) =>
    this.http.request<AddUserApiV1CompaniesCompanyIdUsersPostData, AddUserApiV1CompaniesCompanyIdUsersPostError>({
      path: `/api/v1/companies/${companyId}/users/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  listFleetManagersByCompanyApiV1CompaniesCompanyIdFleetsGet = (
    { companyId, ...query }: ListFleetManagersByCompanyApiV1CompaniesCompanyIdFleetsGetParams,
    params: RequestParams = {}
  ) =>
    this.http.request<
      ListFleetManagersByCompanyApiV1CompaniesCompanyIdFleetsGetData,
      ListFleetManagersByCompanyApiV1CompaniesCompanyIdFleetsGetError
    >({
      path: `/api/v1/companies/${companyId}/fleets/`,
      method: 'GET',
      query: query,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  addFleetManagerApiV1CompaniesCompanyIdFleetsPost = (companyId: any, data: UserCreate, params: RequestParams = {}) =>
    this.http.request<
      AddFleetManagerApiV1CompaniesCompanyIdFleetsPostData,
      AddFleetManagerApiV1CompaniesCompanyIdFleetsPostError
    >({
      path: `/api/v1/companies/${companyId}/fleets/`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  agreeToTosSelfApiV1UsersSelfTosAgreedPost = (params: RequestParams = {}) =>
    this.http.request<AgreeToTosSelfApiV1UsersSelfTosAgreedPostData, any>({
      path: `/api/v1/users/self/tos/agreed`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
undefined
 *
undefined
 */
  agreeToTosApiV1UsersUserIdTosAgreedPost = (userId: string, params: RequestParams = {}) =>
    this.http.request<AgreeToTosApiV1UsersUserIdTosAgreedPostData, AgreeToTosApiV1UsersUserIdTosAgreedPostError>({
      path: `/api/v1/users/${userId}/tos/agreed`,
      method: 'POST',
      ...params,
    });
}
