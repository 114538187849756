import { MutableSnapshot } from 'recoil';
import { userStorage, tokenStorage } from 'lib/userStorage';
import { authTokenState, userAtom } from './authState';

export default function recoilInitializer({ set }: MutableSnapshot) {
  const storedToken = tokenStorage.get();
  const storedUser = userStorage.get();
  if (storedToken) {
    set(authTokenState, storedToken);
  }
  if (storedUser) {
    set(userAtom, storedUser);
  }
}
