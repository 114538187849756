import LineIcon from 'assets/icons/line.svg';
import TalkIcon from 'assets/icons/talk.svg';
import Training from 'assets/icons/training.svg';
import VesselIcon from 'assets/icons/vessel.svg';
import AccoutIcon from 'assets/icons/account.svg';
import SettingIcon from 'assets/icons/setting.svg';
import { Role } from 'services/schema';

export type WinsRouterEntity = {
  type: 'PAGE' | 'SYSTEM';
  pageTitle: string;
  navigationLabel: string;
  location: string;
  icon: string;
  allowedRoles: 'ANY' | Role[];
};

export const WINS_ROUTER: WinsRouterEntity[] = [
  {
    type: 'PAGE',
    pageTitle: 'Vessels',
    navigationLabel: 'Vessels',
    location: 'vessels',
    icon: VesselIcon,
    allowedRoles: 'ANY',
  },
  { type: 'PAGE', pageTitle: 'Lines', navigationLabel: 'Lines', location: 'lines', icon: LineIcon, allowedRoles: 'ANY' },
  { type: 'PAGE', pageTitle: 'Talk', navigationLabel: 'Talk', location: 'linetalk', icon: TalkIcon, allowedRoles: 'ANY' },
  {
    type: 'PAGE',
    pageTitle: 'Trainings',
    navigationLabel: 'Trainings',
    location: 'trainings',
    icon: Training,
    allowedRoles: 'ANY',
  },
  {
    type: 'SYSTEM',
    pageTitle: 'Setting',
    navigationLabel: 'Setting',
    location: 'setting',
    icon: SettingIcon,
    allowedRoles: ['DSR Staff'],
  },
  {
    type: 'SYSTEM',
    pageTitle: 'Account',
    navigationLabel: 'Account',
    location: 'account',
    icon: AccoutIcon,
    allowedRoles: 'ANY',
  },
];
