import { css, styled } from 'theme/stitches.config';


const WELCOME_TITLE = {
  fontFamily: '$semibold',
  fontWeight: '$semibold',
  fontSize: '$index',
  lineHeight: '$default',
}
export const WelcomeTitle = styled('h1', WELCOME_TITLE);



export const WELCOME_INPUT = {
  fontFamily: '$regular',
  fontWeight: '$regular',
  fontSize: '$page2',
  lineHeight: '$default',
}
export const createWelcomeInputStyle = css(WELCOME_INPUT);
export const GnbMenu = styled('span', {
  fontFamily: '$regular',
  fontSize: '$body1',
  lineHeight: '$default',
  color: '$white'
})


const LOGO = {
  fontFamily: '$semibold',
  fontWeight: '$semibold',
  fontSize: '$logo',
  color: '$white',
  lineHeight: '$default',
}
export const Logo = styled('h1', LOGO)
export const createLogoStyle = css(LOGO);

export const Page1 = styled('h1', {
  fontFamily: '$semibold',
  fontWeight: '$semibold',
  fontSize: '$page1',
  lineHeight: '$default',
});
export const Page2 = styled('h2', {
  fontFamily: '$semibold',
  fontWeight: '$semibold',
  fontSize: '$page2',
  lineHeight: '$default',
});
export const Page3 = styled('h3', {
  fontFamily: '$semibold',
  fontWeight: '$semibold',
  fontSize: '$page3',
  lineHeight: '$default',
});


const BODY1 = {
  variants: {
    weight: {
      bold: {
        fontFamily: '$semibold',
        fontWeight: '$semibold',
      },
      medium:  {
        fontFamily: '$medium',
        fontWeight: '$medium',
      }, 
      regular: {
        fontFamily: '$regular',
        fontWeight: '$regular',
      }
    }
  },
  fontSize: '$body1',
  lineHeight: '$default',
}
export const Body1 = styled('span', BODY1);
export const createBody1Style = css(BODY1);
const BODY2 = {
  variants: {
    weight: {
      medium:  {
        fontFamily: '$medium',
        fontWeight: '$medium',
      }, 
      regular: {
        fontFamily: '$regular',
        fontWeight: '$regular',
      }
    },
    link: {
      true: {
        textDecoration: 'underline'
      }
    }
  },
  fontSize: '$body2',
  lineHeight: '$default',
}
export const Body2 = styled('span', BODY2);
export const createBody2Style = css(BODY2);
const BODY3 = {
  variants: {
    weight: {
      bold: {
        fontFamily: '$semibold',
        fontWeight: '$semibold',
      },
      medium:  {
        fontFamily: '$medium',
        fontWeight: '$medium',
      }, 
      regular: {
        fontFamily: '$regular',
        fontWeight: '$regular',
      }
    }
  },
  fontSize: '$body3',
  lineHeight: '$default',
}
export const Body3 = styled('span', BODY3);
export const createBody3Style = css(BODY3);


const DATE = {
  fontFamily: '$medium',
  fontWeight: '$medium',
  fontSize: '$date',
  lineHeight: '$default',
}
export const Date = styled('span',DATE);
export const createDateStyle = css(DATE);


const BUTTON2 = {
  fontFamily: '$semibold',
  fontWeight: '$semibold',
  fontSize: '$body3',
  lineHeight: '$default',
}
export const Button2 = styled('span', BUTTON2);
export const createButton2Style = css(BUTTON2);