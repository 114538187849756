export const STUB_LINES_BY_VESSEL_ID = {
  total_count: 14,
  offset: 0,
  limit: -1,
  lines: [
    {
      id: '948ec7c3-b1d8-4359-b320-a0141461781e',
      custom_id: null,
      meg4_id: 'PMC833333',
      line_id: 'PMC8',
      working_hours: 72,
      working_hours_limit: 10000,
      is_retired: false,
      mbl: '1192',
      length: '220',
      diameter: '44',
      strand: '12',
      supplied_date: '2023-12-08T00:00:00',
      certificate_issued_date: '2023-12-08T00:00:00',
      certificate_filename: 'DEMO-LINE-1.pdf',
      created_at: '2024-01-23T08:21:05.919351+00:00',
      installed_drum_full_name: 'W2-2',
      installed_time: '2024-07-27T23:22:11',
      product: {
        id: '8f11db6d-434e-4c98-8ad6-c5db247da913',
        manufacture_name: 'DSR',
        product_name: 'SuperMax',
        material_name: 'UHMPE',
      },
      vessel: {
        id: 'a98b907d-e1cd-4437-b6b8-b93fdd33d992',
        hin: 'd-v-11',
        name: 'demo-vessel-11',
        type_name: 'Test Vessel',
        abbreviation: null,
      },
      is_deletable: false,
      status: 'Main',
      installed_at_v2: {
        id: '32ec64f0-c3c5-4c83-9714-8119df323b39',
        name: '2',
        position: 1,
        has_tail_line: false,
        winch: {
          id: '068b31f4-c8dc-4e58-b686-656b703422c0',
          name: 'W2',
          position_x: 2,
          position_y: 4,
          drum_count: 1,
          angle: 4,
        },
      },
      last_visual_inspection: {
        id: '128208bb-1c8d-441b-b4b4-e314b17d0cc6',
        inspected_at: '2024-07-28T00:27:54',
        result: 4,
        memo: '',
      },
      switched_from_v2: null,
      last_turn_over: null,
    },
    {
      id: 'fdfdabcb-f9bb-477b-937d-99abe075e318',
      custom_id: null,
      meg4_id: 'PMC6',
      line_id: 'PMC6',
      working_hours: 72,
      working_hours_limit: 10000,
      is_retired: false,
      mbl: '1192',
      length: '220',
      diameter: '44',
      strand: '12',
      supplied_date: '2023-12-08T00:00:00',
      certificate_issued_date: '2023-12-08T00:00:00',
      certificate_filename: '/TO_BE_UPLOADED',
      created_at: '2024-01-23T08:21:05.889970+00:00',
      installed_drum_full_name: 'W3-3',
      installed_time: '2024-07-28T00:28:52',
      product: {
        id: '8f11db6d-434e-4c98-8ad6-c5db247da913',
        manufacture_name: 'DSR',
        product_name: 'SuperMax',
        material_name: 'UHMPE',
      },
      vessel: {
        id: 'a98b907d-e1cd-4437-b6b8-b93fdd33d992',
        hin: 'd-v-11',
        name: 'demo-vessel-11',
        type_name: 'Test Vessel',
        abbreviation: null,
      },
      is_deletable: false,
      status: 'Main',
      installed_at_v2: {
        id: '19e9c61a-bf55-4478-af69-25428142fbfd',
        name: '3',
        position: 1,
        has_tail_line: false,
        winch: {
          id: '4eb74842-d0fd-4d01-82cc-8809d316a51e',
          name: 'W3',
          position_x: 6,
          position_y: 0,
          drum_count: 1,
          angle: 0,
        },
      },
      last_visual_inspection: null,
      switched_from_v2: null,
      last_turn_over: {
        id: 'bca8f1c9-d4cb-42f2-bbb1-8b735c23cbb7',
        turn_overed_at: '2024-07-28T00:34:42',
        memo: '',
      },
    },
    {
      id: 'f76c77e2-83e1-46a5-bf07-a928978e20d1',
      custom_id: null,
      meg4_id: 'PMC1',
      line_id: 'PMC1',
      working_hours: 0,
      working_hours_limit: 10000,
      is_retired: false,
      mbl: '1192',
      length: '220',
      diameter: '44',
      strand: '12',
      supplied_date: '2023-12-08T00:00:00',
      certificate_issued_date: '2023-12-08T00:00:00',
      certificate_filename: '/TO_BE_UPLOADED',
      created_at: '2024-01-23T08:21:05.794925+00:00',
      installed_drum_full_name: '',
      installed_time: null,
      product: {
        id: '8f11db6d-434e-4c98-8ad6-c5db247da913',
        manufacture_name: 'DSR',
        product_name: 'SuperMax',
        material_name: 'UHMPE',
      },
      vessel: {
        id: 'a98b907d-e1cd-4437-b6b8-b93fdd33d992',
        hin: 'd-v-11',
        name: 'demo-vessel-11',
        type_name: 'Test Vessel',
        abbreviation: null,
      },
      is_deletable: true,
      status: 'Uninstalled',
      installed_at_v2: null,
      last_visual_inspection: null,
      switched_from_v2: null,
      last_turn_over: null,
    },
    {
      id: '19561459-486e-462e-a6c4-4cd0f56a21f2',
      custom_id: null,
      meg4_id: 'PMC10',
      line_id: 'PMC10',
      working_hours: 0,
      working_hours_limit: 10000,
      is_retired: false,
      mbl: '1192',
      length: '220',
      diameter: '44',
      strand: '12',
      supplied_date: '2023-12-08T00:00:00',
      certificate_issued_date: '2023-12-08T00:00:00',
      certificate_filename: '/TO_BE_UPLOADED',
      created_at: '2024-01-23T08:21:05.949177+00:00',
      installed_drum_full_name: '',
      installed_time: null,
      product: {
        id: '8f11db6d-434e-4c98-8ad6-c5db247da913',
        manufacture_name: 'DSR',
        product_name: 'SuperMax',
        material_name: 'UHMPE',
      },
      vessel: {
        id: 'a98b907d-e1cd-4437-b6b8-b93fdd33d992',
        hin: 'd-v-11',
        name: 'demo-vessel-11',
        type_name: 'Test Vessel',
        abbreviation: null,
      },
      is_deletable: true,
      status: 'Uninstalled',
      installed_at_v2: null,
      last_visual_inspection: null,
      switched_from_v2: null,
      last_turn_over: null,
    },
    {
      id: '9eec7fe6-b8ec-4d73-b392-48401ffa6744',
      custom_id: null,
      meg4_id: 'PMC11',
      line_id: 'PMC11',
      working_hours: 0,
      working_hours_limit: 10000,
      is_retired: false,
      mbl: '1192',
      length: '220',
      diameter: '44',
      strand: '12',
      supplied_date: '2023-12-08T00:00:00',
      certificate_issued_date: '2023-12-08T00:00:00',
      certificate_filename: '/TO_BE_UPLOADED',
      created_at: '2024-01-23T08:21:05.963339+00:00',
      installed_drum_full_name: '',
      installed_time: null,
      product: {
        id: '8f11db6d-434e-4c98-8ad6-c5db247da913',
        manufacture_name: 'DSR',
        product_name: 'SuperMax',
        material_name: 'UHMPE',
      },
      vessel: {
        id: 'a98b907d-e1cd-4437-b6b8-b93fdd33d992',
        hin: 'd-v-11',
        name: 'demo-vessel-11',
        type_name: 'Test Vessel',
        abbreviation: null,
      },
      is_deletable: true,
      status: 'Uninstalled',
      installed_at_v2: null,
      last_visual_inspection: null,
      switched_from_v2: null,
      last_turn_over: null,
    },
    {
      id: '92a1caef-e19c-4939-b322-40345df4540f',
      custom_id: null,
      meg4_id: 'PMC12',
      line_id: 'PMC12',
      working_hours: 0,
      working_hours_limit: 10000,
      is_retired: false,
      mbl: '1192',
      length: '220',
      diameter: '44',
      strand: '12',
      supplied_date: '2023-12-08T00:00:00',
      certificate_issued_date: '2023-12-08T00:00:00',
      certificate_filename: '/TO_BE_UPLOADED',
      created_at: '2024-01-23T08:21:05.979558+00:00',
      installed_drum_full_name: '',
      installed_time: null,
      product: {
        id: '8f11db6d-434e-4c98-8ad6-c5db247da913',
        manufacture_name: 'DSR',
        product_name: 'SuperMax',
        material_name: 'UHMPE',
      },
      vessel: {
        id: 'a98b907d-e1cd-4437-b6b8-b93fdd33d992',
        hin: 'd-v-11',
        name: 'demo-vessel-11',
        type_name: 'Test Vessel',
        abbreviation: null,
      },
      is_deletable: true,
      status: 'Uninstalled',
      installed_at_v2: null,
      last_visual_inspection: null,
      switched_from_v2: null,
      last_turn_over: null,
    },
    {
      id: '2f78fc7a-c726-4e56-8301-8b6d8cabdd75',
      custom_id: null,
      meg4_id: 'PMC14',
      line_id: 'PMC14',
      working_hours: 0,
      working_hours_limit: 10000,
      is_retired: false,
      mbl: '1192',
      length: '220',
      diameter: '44',
      strand: '12',
      supplied_date: '2023-12-08T00:00:00',
      certificate_issued_date: '2023-12-08T00:00:00',
      certificate_filename: '/TO_BE_UPLOADED',
      created_at: '2024-01-23T08:21:06.009276+00:00',
      installed_drum_full_name: '',
      installed_time: null,
      product: {
        id: '8f11db6d-434e-4c98-8ad6-c5db247da913',
        manufacture_name: 'DSR',
        product_name: 'SuperMax',
        material_name: 'UHMPE',
      },
      vessel: {
        id: 'a98b907d-e1cd-4437-b6b8-b93fdd33d992',
        hin: 'd-v-11',
        name: 'demo-vessel-11',
        type_name: 'Test Vessel',
        abbreviation: null,
      },
      is_deletable: true,
      status: 'Uninstalled',
      installed_at_v2: null,
      last_visual_inspection: null,
      switched_from_v2: null,
      last_turn_over: null,
    },
    {
      id: 'db2df762-b4e4-4c8d-950a-61cb98b1e41c',
      custom_id: null,
      meg4_id: 'PMC15',
      line_id: 'PMC15',
      working_hours: 0,
      working_hours_limit: 10000,
      is_retired: false,
      mbl: '1192',
      length: '220',
      diameter: '44',
      strand: '12',
      supplied_date: '2023-12-08T00:00:00',
      certificate_issued_date: '2023-12-08T00:00:00',
      certificate_filename: '/TO_BE_UPLOADED',
      created_at: '2024-01-23T08:21:06.023333+00:00',
      installed_drum_full_name: '',
      installed_time: null,
      product: {
        id: '8f11db6d-434e-4c98-8ad6-c5db247da913',
        manufacture_name: 'DSR',
        product_name: 'SuperMax',
        material_name: 'UHMPE',
      },
      vessel: {
        id: 'a98b907d-e1cd-4437-b6b8-b93fdd33d992',
        hin: 'd-v-11',
        name: 'demo-vessel-11',
        type_name: 'Test Vessel',
        abbreviation: null,
      },
      is_deletable: true,
      status: 'Uninstalled',
      installed_at_v2: null,
      last_visual_inspection: null,
      switched_from_v2: null,
      last_turn_over: null,
    },
    {
      id: 'd396d5e3-5ec9-4461-aef8-f7f746281747',
      custom_id: null,
      meg4_id: 'PMC16',
      line_id: 'PMC16',
      working_hours: 0,
      working_hours_limit: 10000,
      is_retired: false,
      mbl: '1192',
      length: '220',
      diameter: '44',
      strand: '12',
      supplied_date: '2023-12-08T00:00:00',
      certificate_issued_date: '2023-12-08T00:00:00',
      certificate_filename: '/TO_BE_UPLOADED',
      created_at: '2024-01-23T08:21:06.037453+00:00',
      installed_drum_full_name: '',
      installed_time: null,
      product: {
        id: '8f11db6d-434e-4c98-8ad6-c5db247da913',
        manufacture_name: 'DSR',
        product_name: 'SuperMax',
        material_name: 'UHMPE',
      },
      vessel: {
        id: 'a98b907d-e1cd-4437-b6b8-b93fdd33d992',
        hin: 'd-v-11',
        name: 'demo-vessel-11',
        type_name: 'Test Vessel',
        abbreviation: null,
      },
      is_deletable: true,
      status: 'Uninstalled',
      installed_at_v2: null,
      last_visual_inspection: null,
      switched_from_v2: null,
      last_turn_over: null,
    },
    {
      id: 'acc53251-1580-4efc-b82a-3afe7cddca16',
      custom_id: null,
      meg4_id: 'PMC2',
      line_id: 'PMC2',
      working_hours: 0,
      working_hours_limit: 10000,
      is_retired: false,
      mbl: '1192',
      length: '220',
      diameter: '44',
      strand: '12',
      supplied_date: '2023-12-08T00:00:00',
      certificate_issued_date: '2023-12-08T00:00:00',
      certificate_filename: '/TO_BE_UPLOADED',
      created_at: '2024-01-23T08:21:05.827260+00:00',
      installed_drum_full_name: '',
      installed_time: null,
      product: {
        id: '8f11db6d-434e-4c98-8ad6-c5db247da913',
        manufacture_name: 'DSR',
        product_name: 'SuperMax',
        material_name: 'UHMPE',
      },
      vessel: {
        id: 'a98b907d-e1cd-4437-b6b8-b93fdd33d992',
        hin: 'd-v-11',
        name: 'demo-vessel-11',
        type_name: 'Test Vessel',
        abbreviation: null,
      },
      is_deletable: true,
      status: 'Uninstalled',
      installed_at_v2: null,
      last_visual_inspection: null,
      switched_from_v2: null,
      last_turn_over: null,
    },
    {
      id: '5f1d1dea-6c39-404d-8700-4038787c91f6',
      custom_id: null,
      meg4_id: 'PMC3',
      line_id: 'PMC3',
      working_hours: 0,
      working_hours_limit: 10000,
      is_retired: false,
      mbl: '1192',
      length: '220',
      diameter: '44',
      strand: '12',
      supplied_date: '2023-12-08T00:00:00',
      certificate_issued_date: '2023-12-08T00:00:00',
      certificate_filename: '/TO_BE_UPLOADED',
      created_at: '2024-01-23T08:21:05.841487+00:00',
      installed_drum_full_name: '',
      installed_time: null,
      product: {
        id: '8f11db6d-434e-4c98-8ad6-c5db247da913',
        manufacture_name: 'DSR',
        product_name: 'SuperMax',
        material_name: 'UHMPE',
      },
      vessel: {
        id: 'a98b907d-e1cd-4437-b6b8-b93fdd33d992',
        hin: 'd-v-11',
        name: 'demo-vessel-11',
        type_name: 'Test Vessel',
        abbreviation: null,
      },
      is_deletable: true,
      status: 'Uninstalled',
      installed_at_v2: null,
      last_visual_inspection: null,
      switched_from_v2: null,
      last_turn_over: null,
    },
    {
      id: 'd198818c-7e5d-4bae-b34b-04358c5b2e85',
      custom_id: null,
      meg4_id: 'PMC4',
      line_id: 'PMC4',
      working_hours: 0,
      working_hours_limit: 10000,
      is_retired: false,
      mbl: '1192',
      length: '220',
      diameter: '44',
      strand: '12',
      supplied_date: '2023-12-08T00:00:00',
      certificate_issued_date: '2023-12-08T00:00:00',
      certificate_filename: '/TO_BE_UPLOADED',
      created_at: '2024-01-23T08:21:05.855607+00:00',
      installed_drum_full_name: '',
      installed_time: null,
      product: {
        id: '8f11db6d-434e-4c98-8ad6-c5db247da913',
        manufacture_name: 'DSR',
        product_name: 'SuperMax',
        material_name: 'UHMPE',
      },
      vessel: {
        id: 'a98b907d-e1cd-4437-b6b8-b93fdd33d992',
        hin: 'd-v-11',
        name: 'demo-vessel-11',
        type_name: 'Test Vessel',
        abbreviation: null,
      },
      is_deletable: true,
      status: 'Uninstalled',
      installed_at_v2: null,
      last_visual_inspection: null,
      switched_from_v2: null,
      last_turn_over: null,
    },
    {
      id: 'de3466d4-5a12-43ea-931d-cc05e15b21f5',
      custom_id: null,
      meg4_id: 'PMC5',
      line_id: 'PMC5',
      working_hours: 0,
      working_hours_limit: 10000,
      is_retired: false,
      mbl: '1192',
      length: '220',
      diameter: '44',
      strand: '12',
      supplied_date: '2023-12-08T00:00:00',
      certificate_issued_date: '2023-12-08T00:00:00',
      certificate_filename: '/TO_BE_UPLOADED',
      created_at: '2024-01-23T08:21:05.874997+00:00',
      installed_drum_full_name: '',
      installed_time: null,
      product: {
        id: '8f11db6d-434e-4c98-8ad6-c5db247da913',
        manufacture_name: 'DSR',
        product_name: 'SuperMax',
        material_name: 'UHMPE',
      },
      vessel: {
        id: 'a98b907d-e1cd-4437-b6b8-b93fdd33d992',
        hin: 'd-v-11',
        name: 'demo-vessel-11',
        type_name: 'Test Vessel',
        abbreviation: null,
      },
      is_deletable: true,
      status: 'Uninstalled',
      installed_at_v2: null,
      last_visual_inspection: null,
      switched_from_v2: null,
      last_turn_over: null,
    },
    {
      id: '45b4f53d-18bf-4f70-8df5-f14b3fecf190',
      custom_id: null,
      meg4_id: 'PMC9',
      line_id: 'PMC9',
      working_hours: 0,
      working_hours_limit: 10000,
      is_retired: false,
      mbl: '1192',
      length: '220',
      diameter: '44',
      strand: '12',
      supplied_date: '2023-12-08T00:00:00',
      certificate_issued_date: '2023-12-08T00:00:00',
      certificate_filename: 'DEMO-LINE-1.pdf',
      created_at: '2024-01-23T08:21:05.933395+00:00',
      installed_drum_full_name: '',
      installed_time: null,
      product: {
        id: '8f11db6d-434e-4c98-8ad6-c5db247da913',
        manufacture_name: 'DSR',
        product_name: 'SuperMax',
        material_name: 'UHMPE',
      },
      vessel: {
        id: 'a98b907d-e1cd-4437-b6b8-b93fdd33d992',
        hin: 'd-v-11',
        name: 'demo-vessel-11',
        type_name: 'Test Vessel',
        abbreviation: null,
      },
      is_deletable: false,
      status: 'Uninstalled',
      installed_at_v2: null,
      last_visual_inspection: null,
      switched_from_v2: {
        id: '19e9c61a-bf55-4478-af69-25428142fbfd',
        name: '3',
        position: 1,
        has_tail_line: false,
        winch: {
          id: '4eb74842-d0fd-4d01-82cc-8809d316a51e',
          name: 'W3',
          position_x: 6,
          position_y: 0,
          drum_count: 1,
          angle: 0,
        },
      },
      last_turn_over: null,
    },
  ],
};
