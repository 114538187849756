import { Toast, ToastProvider, globalToastDataState } from 'components/common/Toast/ErrorToast';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { SWRConfig } from 'swr';
import { swrRequestLogger } from './lib/requestLogger';
import { WinsRouter } from './router';
import { Toaster } from 'react-hot-toast';

const router = createBrowserRouter([{ path: '*', Component: WinsRouter }]);

function App() {
  const apiError = useRecoilValue(globalToastDataState);

  return (
    <SWRConfig value={{ use: [swrRequestLogger] }}>
      <ToastProvider swipeDirection='right'>
        <RouterProvider router={router} />
        {apiError && <Toast />}
      </ToastProvider>
      <Toaster />
    </SWRConfig>
  );
}

export default App;
