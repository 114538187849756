import { globalCss } from '@stitches/react';
import PoppinsRegular from 'assets/fonts/Poppins-Regular.ttf';
import PoppinsMedium from 'assets/fonts/Poppins-Medium.ttf';
import PoppinsSemiBold from 'assets/fonts/Poppins-SemiBold.ttf';

export const globalStyles = globalCss({
  '*': { margin: 0, padding: 0, boxSizing: 'border-box' },
  '@font-face': [
    {
      fontFamily: 'Poppins-Regular',
      src: `url(${PoppinsRegular}) format("truetype")`,
    },
    {
      fontFamily: 'Poppins-Medium',
      src: `url(${PoppinsMedium}) format("truetype")`,
    },
    {
      fontFamily: 'Poppins-SemiBold',
      src: `url(${PoppinsSemiBold}) format("truetype")`,
    },
  ],
  'html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary, time, mark, audio, video':
    {
      margin: 0,
      padding: 0,
      border: 0,
      fontSize: '100%',
      font: 'inherit',
      verticalAlign: 'middle',
    },
  'html, body, #root': {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
  },
  'a': {
    textDecoration: 'none',
    color: 'inherit',
  },
  /* HTML5 display-role reset for older browsers */
  'article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section': {
    display: 'block',
  },
  body: {
    lineHeight: 1,
    overflow: 'inherit'
  },
  'ol, ul': {
    listStyle: 'none',
  },
  'blockquote, q': {
    quotes: 'none',
  },
  'blockquote:before, blockquote:after, q:before, q:after': {
    content: '',
  },
  button: {
    border: 0
  },
  table: {
    borderCollapse: 'collapse',
    borderSpacing: 0,
  },
  svg: {
    verticalAlign: 'middle',
  },
  'div': {
    boxSizing: 'border-box' 
  }
});
