import { useEffect } from 'react';
import { useRecoilSnapshot } from 'recoil';
import { Middleware, SWRHook } from 'swr';

export const swrRequestLogger: Middleware = (useSWRNext: SWRHook) => {
  return (key, fetcher, config) => {
    if(process.env.NODE_ENV === 'development') {

      // 원본 fetcher에 logger를 추가합니다.
      const extendedFetcher = (...args: unknown[]) => {
        console.log('SWR Request:', key);
        return fetcher!(...args);
      };
  
      // 새로운 fetcher로 hook을 실행합니다.
      return useSWRNext(key, extendedFetcher, config);
    } else {
      return useSWRNext(key, fetcher, config);
    }
  };
};


export const DebugObserver = (): JSX.Element => {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    for (const node of snapshot.getNodes_UNSTABLE({isModified: true})) {
      console.debug(`[RECOIL OBSERVER]`, node.key, snapshot.getLoadable(node));
    }
  }, [snapshot]);

  return <div/>;
}