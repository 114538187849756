import { QueryClient } from '@tanstack/react-query';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { tokenStorage } from 'lib/userStorage';
import { Token } from 'services/auth/model';
import { HttpClient } from 'swagger/http-client';
import toast from 'react-hot-toast';

const BASE_URL = process.env.REACT_APP_API_HOST || '';
if (process.env.NODE_ENV === 'development' && !BASE_URL) {
  console.error('REACT_APP_API_HOST is undefined!');
}

const commonConfig: AxiosRequestConfig = { headers: {}, baseURL: BASE_URL, withCredentials: true };
export type WinsAdminAxios = AxiosInstance & {
  accessToken: string;
  refreshToken?: string;
  tokenType: string; // Bearer
};


const isBlobLike = (value: unknown): value is File | Blob => value instanceof Blob || value instanceof File;
const transformFormDataForAttachments = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const { data, method } = config;
  if (method !== 'GET' && data) {
    const fields = Object.entries(data);
    const anyBlobItem = fields.find(([, value]) => isBlobLike(value));
    if (anyBlobItem) {
      const newData = new FormData();
      fields.forEach(([key, value]) => {
        newData.append(key, value as Blob);
      });

      return { ...config, data: newData };
    }
  }
  return config;
};
const attachAuthToken = (config: AxiosRequestConfig) => {
  const token = tokenStorage.get();
  if (token?.accessToken) {
    config.headers = {
      ...config.headers,
      Authorization: `${token.tokenType} ${token.accessToken}`,
    };
  }
  return config;
};

export const http: HttpClient = new HttpClient(commonConfig);

//>>>> Instantiate the interceptor

// 디바운스 요청을 저장할 맵
const pendingRequests = new Map();

// 디바운스 함수
function debounceRequest(config: AxiosRequestConfig<any>,  delay = 200) {
  const requestKey = JSON.stringify({
    url: config.url,
    method: config.method,
    params: config.params,
    data: config.data,
  });

  // 이전 요청이 있다면 취소
  if (pendingRequests.has(requestKey)) {
    const { timeoutId, cancelToken } = pendingRequests.get(requestKey);
    clearTimeout(timeoutId);
    cancelToken.cancel('Debounced request cancelled');
  }

  // 새로운 취소 토큰 생성
  const source = axios.CancelToken.source();
  config.cancelToken = source.token;

  // 새로운 프로미스 생성
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      pendingRequests.delete(requestKey);
      resolve(config);
    }, delay);

    pendingRequests.set(requestKey, {
      timeoutId,
      cancelToken: source,
    });
  });
}
http.instance.interceptors.request.use(
  async config => {
    try {
      // GET 요청에만 디바운싱 적용 (필요에 따라 수정 가능)
      if (config.method === 'get') {
        const debouncedConfig = await debounceRequest(config);
        return debouncedConfig;
      }
      return config;
    } catch (error) {
      if (axios.isCancel(error)) {
        return Promise.reject(error);
      }
      throw error;
    }
  },
  error => {
    return Promise.reject(error);
  }
);
http.instance.interceptors.request.use(config => {
  const authData = queryClient.getQueryData(['auth']) || tokenStorage.get();
  // @ts-ignore
  const accessToken = authData?.accessToken;
  if (authData !== null) {
    config['headers'] = { Authorization: 'Bearer ' + accessToken };
  }

  return config;
});
http.instance.interceptors.request.use(transformFormDataForAttachments);
//>>>> Instantiate the interceptor

let isInProgressRefreshToken = false;
export const setAuth = (queryClient: QueryClient, token: Token) => {
  queryClient.setQueryData(['auth'], token);

  const authData = {
    accessToken: token.accessToken,
    refreshToken: token.refreshToken,
    tokenType: token.tokenType,
  };

  tokenStorage.set(authData);
};
export const clearAuth = (queryClient: QueryClient): void => {
  queryClient.setQueryData(['auth'], null);
  queryClient.clear();
  tokenStorage.clear();
};
function updateToken(): Promise<string> {
  isInProgressRefreshToken = true;

  const token = tokenStorage.get();
  if (!token || !token.refreshToken) {
    isInProgressRefreshToken = true;
    clearAuth(queryClient);
    window.location.replace('/login'); // NOTE: WNZ-65 if refresh token expired, redirect to `/login` page
    throw new Error('No refresh token available');
  }
  const { accessToken, tokenType, refreshToken } = token;

  return new Promise((resolve, reject) => {
    axios
      .request({
        baseURL: BASE_URL,
        method: 'POST',
        url: `/api/v1/tokens/refresh`,
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
          'refresh-Token': refreshToken,
        },
      })
      .then(response => {
        if (!response) throw new Error('refresh token is not exist.');
        const { access_token, refresh_token, token_type } = response.data;
        tokenStorage.set({
          accessToken: access_token,
          refreshToken: refresh_token,
          tokenType: token_type,
        });
        isInProgressRefreshToken = false;
        resolve(access_token);
      })
      .catch(error => {
        console.error('updateToken failed:', error);
        isInProgressRefreshToken = true;
        clearAuth(queryClient);
        reject(error);
      });
  });
}

const requestWaitQueue = [] as Array<(newAccessToken: string) => void>;
const responseInterceptor = async (error: AxiosError) => {
  const status = error.response?.status;
  const message = error.response?.data
    ? (error.response.data as { detail: string })
    : { detail: 'An error occurred, Please try again later.' };

  if (!status) {
    toast.error(message.detail, {
      duration: 2000,
      position: 'bottom-center',
    });

    return Promise.resolve(error);
  }

  if (error.config && status === 401) {
    const { config: requestConfigToRetry } = error;

    if (!isInProgressRefreshToken) {
      updateToken()
        .then(access_token => {
          while (requestWaitQueue.length > 0) {
            const waitingRequest = requestWaitQueue.shift();
            if (!waitingRequest || !access_token) return;
            waitingRequest(access_token);
          }
        })
        .catch(error => {
          window.location.replace('/login'); // NOTE: WNZ-65 if refresh token expired, redirect to `/login` page
          // return Promise.reject(error);
        })
        .finally(() => {
          return;
        });
    }

    return new Promise(resolve => {
      requestWaitQueue.push(newAccessToken => {
        requestConfigToRetry['headers'] = {
          ...requestConfigToRetry.headers,
          Authorization: `Bearer ${newAccessToken}`,
        };

        resolve(axios.request(requestConfigToRetry));
      });
    });
  } else {
    toast.error(message.detail, {
      duration: 2000,
      position: 'bottom-center',
    });
    return Promise.resolve(error);
  }
};
axios.interceptors.response.use(undefined, async error => {
  return responseInterceptor(error);
});

http.instance.interceptors.response.use(undefined, async error => {
  return await responseInterceptor(error);
});

export const winsAdminAxios = http.instance as WinsAdminAxios;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 1000 * 60 * 10, // 10 minutes
      throwOnError: false,
    },
  },
});
