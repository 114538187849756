import { useCallback } from 'react';
import RMDrawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { atom, useRecoilState } from 'recoil';
import { styled } from 'theme/stitches.config';

export const drawerState = atom<Record<string, { isOpened: boolean }> | null>({
  key: 'linetalkDrawerState',
  default: null,
});
export enum DRAWER_ID {
  LINE_TALK = 'LINE_TALK',
}





export const useDrawerState = () => {
  const [drawer, setDrawer] = useRecoilState(drawerState);
  const openDrawer = useCallback(
    (drawerName: string) => {
      setDrawer(prev => {
        if (prev) {
          return {
            ...prev,
            [drawerName]: {
              isOpened: true,
            },
          };
        } else {
          return {
            [drawerName]: {
              isOpened: true,
            },
          };
        }
      });
    },
    []
  );
  const closeDrawer = useCallback(
    (drawerName: string) => {
      setDrawer(prev => {
        if (prev) {
          return {
            ...prev,
            [drawerName]: {
              isOpened: false,
            },
          };
        } else {
          return {
            [drawerName]: {
              isOpened: false,
            },
          };
        }
      });
    },
    []
  );
  const toggleDrawer = useCallback(
    (drawerName: string) => {
      setDrawer(prev => {
        if (prev) {
          return {
            ...prev,
            [drawerName]: {
              isOpened: !prev[drawerName].isOpened,
            },
          };
        } else {
          return {
            [drawerName]: {
              isOpened: true,
            },
          };
        }
      });
    },
    []
  );
  return {
    drawer,
    openDrawer,
    closeDrawer,
    toggleDrawer,
  };
};


type Props = {
  id: DRAWER_ID;
  direction: 'left' | 'right' | 'top' | 'bottom';
  children: React.ReactNode;
};
const Container = styled('div', {
  '.EZDrawer__overlay': {
    backgroundColor: 'transparent !important',
  },
  '.EZDrawer__container': {
    left: '128px !important',
    transitionProperty: 'transform !important'
  },
  'nav': {
    width: '280px !important',
  },
});

export const Drawer = ({ id, direction, children }: Props) => {
  const { drawer, closeDrawer } = useDrawerState();
  const open = drawer?.[id]?.isOpened || false;
  const onClose = () => {
    closeDrawer(id);
  };
  return (
    <Container>
      <RMDrawer open={open} direction={direction} onClose={onClose}>
        {children}
      </RMDrawer>
    </Container>
  );
};
