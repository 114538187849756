import { useUserState } from 'atoms/authState';
import { Layout } from 'components/common/Layout';
import { PageLoader } from 'components/common/Loader';
import { useAuth } from 'hooks/useAuth';
import { FC, lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { globalStyles } from 'theme/Global';

const LoginView = lazy(() => import('views/Login'));
const DemoLoginView = lazy(() => import('views/DemoLogin'));
const VesselView = lazy(() => import('views/Vessels'));
const AddVesselView = lazy(() => import('views/Vessels/AddVessel'));
const VesselDetailView = lazy(() => import('views/VesselDetail'));
const LineView = lazy(() => import('views/Line'));
const AddLinesView = lazy(() => import('views/Line/AddLines'));
const TrainingsView = lazy(() => import('views/Trainings'));
const TrainingDetailView = lazy(() => import('views/TrainingDetail'));
const AccountView = lazy(() => import('views/Account'));
const SettingView = lazy(() => import('views/Setting'));
const SettingCompaniesView = lazy(() => import('views/Setting/Companies'));
const SettingMyCompanyView = lazy(() => import('views/Setting/MyCompany'));

const NotFoundView = lazy(() => import('views/NotFound'));

export const WinsRouter: FC = () => {
  const { loggedIn } = useAuth();
  const info = useUserState();

  const location = useLocation();
  // The `backgroundLocation` state is the location that we were at when one of
  // the gallery links was clicked. If it's there, use it as the location for
  // the <Routes> so we show the gallery in the background, behind the modal.
  let state = location.state as { backgroundLocation?: Location };
  // <Route path='/' element={<Navigate to='dashboard' replace/>}>

  useEffect(() => {
    const paths = location.pathname.split('/');
    const isLandingPage = paths.some(path => path === 'landing');
    if (isLandingPage) {
      document.getElementById('landing')!.style.display = 'block';
    } else {
      document.getElementById('landing')!.style.display = 'none';
      document.body.style.overflow = 'inherit';
      document.querySelector('html')!.className = '';
      document.body.className = '';
      //@ts-ignore
      if (window.$) {
        //@ts-ignore
        if (window.$.fn.fullpage) {
          //@ts-ignore
          $.fn.fullpage.destroy();
        }
      }

      globalStyles();
    }
  }, [location]);

  return (
    <>
      {loggedIn ? (
        <Routes location={state?.backgroundLocation || location}>
          <Route path='/' element={<Layout />}>
            <Route index element={<Navigate to='vessels' replace />} />
            <Route path='login' element={<RedirectToVessel />} />
            <Route path='demo' element={<RedirectToVessel />} />

            <Route
              path='vessels'
              element={
                <Suspense fallback={<PageLoader />}>
                  <VesselView />
                </Suspense>
              }
            />
            <Route
              path='vessels/add'
              element={
                <Suspense fallback={<PageLoader />}>
                  <AddVesselView />
                </Suspense>
              }
            />
            <Route
              path='vessels/:vesselId'
              element={
                <Suspense fallback={<PageLoader />}>
                  <VesselDetailView />
                </Suspense>
              }
            />
            {/* TODO: nested route로 바꾸기 */}
            <Route
              path='lines'
              element={
                <Suspense fallback={<PageLoader />}>
                  <LineView />
                </Suspense>
              }
            />
            <Route
              path='lines/add'
              element={
                <Suspense fallback={<PageLoader />}>
                  <AddLinesView />
                </Suspense>
              }
            />

            <Route
              path='trainings'
              element={
                <Suspense fallback={<PageLoader />}>
                  <TrainingsView />
                </Suspense>
              }
            />

            <Route
              path='trainings/:materialId'
              element={
                <Suspense fallback={<PageLoader />}>
                  <TrainingDetailView />
                </Suspense>
              }
            />

            <Route
              path='account'
              element={
                <Suspense fallback={<PageLoader />}>
                  <AccountView />
                </Suspense>
              }
            />
            <Route
              path='setting'
              element={
                <Suspense fallback={<PageLoader />}>
                  {info?.role === 'DSR Staff' ? <SettingView /> : <NotFoundView />}
                </Suspense>
              }
            >
              <Route index element={<Navigate to='companies' replace />} />
              <Route
                path='companies'
                element={
                  <Suspense fallback={<PageLoader />}>
                    <SettingCompaniesView />
                  </Suspense>
                }
              />
              <Route
                path='mycompany'
                element={
                  <Suspense fallback={<PageLoader />}>
                    <SettingMyCompanyView />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path='*'
              element={
                <Suspense fallback={<PageLoader />}>
                  <NotFoundView />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route
            path='login'
            element={
              <Suspense fallback={<PageLoader />}>
                <LoginView />
              </Suspense>
            }
          />
          <Route
            path='demo'
            element={
              <Suspense fallback={<PageLoader />}>
                <DemoLoginView />
              </Suspense>
            }
          />
          <Route
            path='*'
            element={
              <Suspense fallback={<PageLoader />}>
                <RedirectToLanding />
              </Suspense>
            }
          />
        </Routes>
      )}
    </>
  );
};

const RedirectToLanding = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/landing/', { replace: true });
    if (!location.pathname.includes('landing')) {
      globalThis.location.reload();
    }
  }, [location.pathname, navigate]);
  return <div></div>;
};
const RedirectToVessel = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/vessels/');
  }, [navigate]);
  return <div></div>;
};
