
//#region util functions
export const scrollToLatest = (postElement: HTMLDivElement) => {
  if (postElement) {
    setTimeout(() => {
      const topPos = postElement?.scrollHeight || 0;
      postElement?.scrollTo(0, topPos);
    }, 500);
  }
};
//#endregion
