import { tokenStorage, userStorage } from 'lib/userStorage';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { Token } from 'services/auth/model';
import { User } from 'services/user/model';

export const authTokenState = atom<Token | null>({
  key: 'authTokenState',
  default: tokenStorage.get(),
});

export const userAtom = atom<User | null>({
  key: 'userState',
  default: userStorage.get(),
});

export const userState = selector({
  key: 'userSelector',
  get: ({ get }) => {
    const storedUser = userStorage.get();
    const user = get(userAtom) || storedUser;
    if(user) {
      return {
        ...user,
        isStaff: user?.role === 'DSR Staff'
      }
    } else {
      return null
    }
  }
});

export const loginState = selector({
  key: 'loggedIn',
  get: ({ get }) => {
    const storedToken = tokenStorage.get();
    const storedUser = userStorage.get();
    const token = get(authTokenState) || storedToken;
    const user = get(userState) || storedUser;
    return !!(token && user);
  },
});

export const useAuthTokenState = () => useRecoilState(authTokenState);
export const useUserState = () => useRecoilValue(userState);

