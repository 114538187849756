import * as Lines from './lines';


export const handlers = [
  ...Object.values(Lines),

  // auth.token,

  // lines.companylines,

  // // companies.fleets,

  // linetalks.list,
  // users.self,
  // // fleets.detail,
  // // fleets.lines,
  
  // // vessels.list,
  // vessels.detail,
  // vessels.drums,
  // // vessels.dashboard,
  // vessels.lines,
  // vessels.startMooring,
  // // vessels.terminateMooring,

  // ports.search
]