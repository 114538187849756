import { format, formatISO, parseISO, compareAsc } from 'date-fns';

export const pseudoSearch = <T extends Record<string, any>>(list: T[], field: string, query: string) =>
  query.trim() === '' ? list : list.filter(item => (item[field] + '').includes(query));

export const parseISODateTime = (isoDate: string | null) => (isoDate ? formatISO(parseISO(isoDate)) : '');
export const parseUTCDateTime = (isoDate: string | null) =>
  isoDate ? format(parseISO(isoDate), 'yyyy-MM-dd HH:mm:ss') : '';
export const parseDateTime = (plainDate: string | null) =>
  plainDate ? format(parseISO(plainDate), 'yyyy-MM-dd HH:mm:ss') : '';
export const parseDate = (plainDate: string | null) => plainDate ? plainDate.split('T')[0] : ''; // 2023-10-31T00:00:00 -> 2023-10-31\

export const compareDates = (dateSource: Date | number, dateTarget: Date | number) => {
  const result = compareAsc(dateSource, dateTarget);

  // date source is
  if (result === -1) return 'past';
  else if (result === 0) return 'present';
  else return 'future';
};
