import { styled } from '@stitches/react';
import { ReactNode, useCallback } from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { keyframes } from 'theme/stitches.config';
import { Body2 } from '../Typography';

type ToastType = 'success' | 'error' | 'info' | 'warning';
type Params = {
  type: ToastType;
  api?: unknown; //APIDescriptor<unknown, unknown>; // api endpoint
  status?: number;
  message?: ReactNode;
  stackTrace?: string[];
};
export const globalToastDataState = atom<Params | null>({
  key: 'globalToastDataState',
  default: null,
});

export const ToastProvider = ToastPrimitive.Provider;

const VIEWPORT_PADDING = 25;
const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
});

const slideIn = keyframes({
  from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
  to: { transform: 'translateX(0)' },
});

const swipeOut = keyframes({
  from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
  to: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
});
export const ToastViewport = styled(ToastPrimitive.Viewport, {
  position: 'fixed',
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  padding: VIEWPORT_PADDING,
  gap: 10,
  width: 390,
  maxWidth: '100vw',
  margin: 0,
  listStyle: 'none',
  zIndex: '$toast',
  outline: 'none',
});
export const ToastRoot = styled(ToastPrimitive.Root, {
  backgroundColor: 'white',
  borderRadius: 6,
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  padding: 15,
  display: 'grid',
  gridTemplateAreas: '"title action" "description action"',
  gridTemplateColumns: 'auto max-content',
  columnGap: 15,
  alignItems: 'center',
  variants: {
    type: {
      success: {
        border: '1px solid $borderToastSuccess',
        color: '$txtToastSuccess',
      },
      error: {
        border: '1px solid $borderToastError',
        color: '$txtToastError',
      },
      info: {
        border: '1px solid $borderToastInfo',
        color: '$txtToastInfo',
      },
      warning: {
        border: '1px solid $borderToastWarning',
        color: '$txtToastWarning',
      },
    },
  },

  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&[data-state="closed"]': {
      animation: `${hide} 100ms ease-in`,
    },
    '&[data-swipe="move"]': {
      transform: 'translateX(var(--radix-toast-swipe-move-x))',
    },
    '&[data-swipe="cancel"]': {
      transform: 'translateX(0)',
      transition: 'transform 200ms ease-out',
    },
    '&[data-swipe="end"]': {
      animation: `${swipeOut} 100ms ease-out`,
    },
  },
});
export const ToastTitle = styled(ToastPrimitive.Title, {
  gridArea: 'title',
  marginBottom: 5,
});
export const ToastDescription = styled(ToastPrimitive.Description, {
  gridArea: 'description',
  margin: 0,
});
export const ToastAction = ToastPrimitive.Action;
export const ToastClose = ToastPrimitive.Close;

export const Toast = () => {
  const data = useRecoilValue(globalToastDataState);

  if (process.env.NODE_ENV === 'development' ) {
    if(data?.type === 'error') {
      console.debug('[WINS-APP] API Error at: ', data);
    }
  }

  return (
    <>
      <ToastRoot open type={data?.type || 'info'}>
        <ToastTitle>
          <Body2 weight='regular'>{data?.message || 'Something went wrong! Please try again in a few minutes.'}</Body2>
        </ToastTitle>
      </ToastRoot>
      <ToastViewport />
    </>
  );
};

export const useToast = () => {
  const [, setState] = useRecoilState(globalToastDataState);

  const render = useCallback(
    (params: Params) => {
      setState(params);
      setTimeout(() => {
        setState(null);
      }, 3000);
    },
    [setState]
  );

  return {
    render,
  };
};
