const BASE_URL = process.env.REACT_APP_API_HOST;
if (!BASE_URL) {
  console.error('REACT_APP_API_HOST is undefined!');
}

export const resolveEndpoint = (endpoint: string) => {
  let _endpoint = endpoint;
  if (_endpoint.startsWith('/')) {
    _endpoint = _endpoint.slice(1);
  }
  return `${BASE_URL}${_endpoint}`;
};


export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));