import { Button } from 'components/common/Button';
import { Form, TextArea } from 'components/common/Form';
import { FileUploader } from 'components/common/Form/FileUploader';
import { Icon } from 'components/common/Icon';
import { PageLoader } from 'components/common/Loader';
import { Body1, Body3, Date } from 'components/common/Typography';
import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { useMessageController } from './contoller.post';
import { useMessageSender } from './controller.sender';
import { useThreadContoller2 } from './controller.thread';
import { Box, Content, FileUpload, Header, IconBox, Message, NewMessageForm, Post } from './styles';

export const Thread = ({ lineId, customId }: { lineId: string; customId: string }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const thread = useThreadContoller2(lineId);
  const posts = useMessageController(lineId, contentRef);
  const sender = useMessageSender(lineId, contentRef);

  return (
    <Box>
      {thread.isPending ? (
        <PageLoader />
      ) : (
        <Header>
          <Body1 weight='bold'>{customId}</Body1>
          {!thread.data.includeDsr && !thread.isNewLinetalk && (
            <IconBox title='Invite DSR staff' onClick={thread.onRequestInviteDSRStaff}>
              <Icon variant='userPlus' />
            </IconBox>
          )}
        </Header>
      )}
      <Content id='linetalk-posts' ref={contentRef}>
        {thread.isNewLinetalk ? (
          <Body3 weight='regular' style={{ marginTop: 64, textAlign: 'center', display: 'block' }}>
            Linetalk hasn't started yet.
            <br />
            Try sending your first message.
          </Body3>
        ) : posts.initialLoading ? (
          <PageLoader />
        ) : (
          <>
            {posts.data.hasNext && (
              <div style={{ marginBottom: 24 }}>
                <Button
                  variant='outlined'
                  color='gray'
                  size='small'
                  fluid
                  onClick={posts.handlers.onLoadMore}
                  disabled={posts.inLoadMore}
                >
                  {'Load more'}
                </Button>
              </div>
            )}
            {posts.data.list.map(post => (
              <Post key={post.id} isMine={post.isMine}>
                {!post.isMine && (
                  <Body3 weight='medium'>
                    {post.author.firstName} {post.author.lastName}
                  </Body3>
                )}
                <div>
                  {post.isMine && <Date>{post.createdAt}</Date>}
                  <Message>
                    <Body3 weight='regular'>{post.message}</Body3>
                    <Body3 weight='regular'>
                      {post.attachments.map(att => (
                        <a
                          key={att.id}
                          role='button'
                          href={att.url}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <Icon variant='attachFile' /> {att.filename}
                          <br />
                        </a>
                      ))}
                    </Body3>
                  </Message>
                  {!post.isMine && <Date>{post.createdAt}</Date>}
                </div>
              </Post>
            ))}
          </>
        )}
      </Content>

      <Form onSubmit={sender.form.handleSubmit(sender.onSubmit)} style={{ height: 154, marginTop: 0 }}>
        <NewMessageForm>
          <TextArea rows={4} style={{ width: 180 }} {...sender.form.register('message')} />
          <Button variant='contained' color='gray' type='submit'>
            SEND
          </Button>
        </NewMessageForm>
        {!thread.isNewLinetalk &&
          (sender.attachments.isPending ? (
            <Icon variant='loading' strokeWidth={4} width={24} height={24} />
          ) : (
            <FileUpload>
              <Controller
                control={sender.form.control}
                name='attachments'
                render={({ field: { onChange } }) => (
                  <FileUploader multiple onChange={onChange}>
                    File upload
                  </FileUploader>
                )}
              />
            </FileUpload>
          ))}
      </Form>
    </Box>
  );
};
