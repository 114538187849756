import { Button } from 'components/common/Button';
import { Icon } from 'components/common/Icon';
import { PageLoader } from 'components/common/Loader';
import { Pagination } from 'components/common/Pagination';
import { Body2 } from 'components/common/Typography';
import { useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';
import { LineTalkThread } from 'services/linetalk/model';
import { useListLinetalksApiV1LinetalksQuery } from 'services/linetalk/queries';
import { styled } from 'theme/stitches.config';

const pagination = atom({
  key: 'threadPaginationState',
  default: {
    offset: 0,
    limit: 10,
  },
});

const useLinesController = () => {
  const [state, update] = useRecoilState(pagination);

  const linesApi = useListLinetalksApiV1LinetalksQuery( { offset: state.offset, limit: state.limit });
  const listData = linesApi.data || { totalCount: 0, list: [] as LineTalkThread[] };

  const handlers = useMemo(
    () => ({
      onRetry: () => {
        linesApi.refetch();
      },
      onPageUpdate: async (pageNumber: number) => {
        const newOffset = state.limit * (pageNumber - 1);
        update({
          offset: newOffset,
          limit: state.limit,
        });
      },
    }),
    [linesApi, state.limit, update]
  );

  return {
    ...linesApi,
    data: listData,
    handlers,
    pagination: { limit: state.limit, current: state.offset / state.limit + 1, update: handlers.onPageUpdate },
  };
};
//#region
const Box = styled('div', {
  marginTop: 32,
  padding: '0 16px',
});

const Card = styled('div', {
  width: '100%',
  padding: '12px 16px',
  background: '$gray100',
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.7,
  },
  '& + &': {
    marginTop: 16,
  },
});
const NoResult = styled('div', {
  width: '100%',
  textAlign: 'center',
  marginTop: 64,
});
//#endregion

const ContentFooter = styled('div', {});

export const Lines = ({ onSelect }: { onSelect: (lineId: string, customId: string) => void }) => {
  const { isPending, error, data, handlers, pagination } = useLinesController();

  return (
    <Box>
      <div>
        {isPending ? (
          <PageLoader />
        ) : error ? (
          <div>
            <Box>
              <Body2>{error.message}</Body2>
              <br />
              <Button variant='outlined' color='primary' onClick={handlers.onRetry}>
                Retry
              </Button>
            </Box>
          </div>
        ) : data.totalCount === 0 ? (
          <NoResult>No result</NoResult>
        ) : (
          data.list.map(({ id, line }) => (
            <Card key={line?.id} onClick={() => line && onSelect(line.id, line.customId || line.meg4Id)}>
              <Body2 weight='regular'>{line?.customId || line?.meg4Id}</Body2>
              <Icon variant='circleChevronRight' />
            </Card>
          ))
        )}
      </div>
      {data.totalCount > 0 && (
        <ContentFooter>
          <Pagination
            totalCount={data.totalCount}
            pageSize={pagination.limit}
            defaultPage={pagination.current}
            onChange={pagination.update}
          />
        </ContentFooter>
      )}
    </Box>
  );
};
