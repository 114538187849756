import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Role, UserType } from '../schema';
import { User } from 'swagger/User';
import { http, queryClient } from 'lib/ajax/axios';
import {
  ListFleetManagersByCompanyApiV1CompaniesCompanyIdFleetsGetData,
  ListFleetManagersByCompanyApiV1CompaniesCompanyIdFleetsGetParams,
  ListNotificationsByUserApiV1UsersSelfNotificationsGetParams,
  UserCreate,
  UserUpdate,
} from 'swagger/data-contracts';

export const userApi = new User(http);

export const useGetUserSelfApiV1UsersSelfQuery = (queryOptions?: { enabled: boolean }) =>
  useQuery({
    queryKey: ['users', 'self'],
    queryFn: async () => {
      const res = await userApi.getUserSelfApiV1UsersSelfGet();

      return res.data;
    },
    ...queryOptions,
  });

export const useUpdateUserSelfApiV1UsersSelfMutation = () => {
  return useMutation({
    mutationFn: (data: UserUpdate) => {
      return userApi.updateUserSelfApiV1UsersSelfPatch(data);
    },
    onSuccess: res => {
      queryClient.setQueryData(['users', 'self'], res.data);
    },
  });
};

export const useGetUserApiV1UsersUserIdQuery = (userId: string) =>
  useQuery({
    queryKey: ['users', 'detail', userId],
    queryFn: async () => {
      const res = await userApi.getUserApiV1UsersUserIdGet(userId);
      return {
        id: res.data.id,
        email: res.data.email,
        firstName: res.data.first_name,
        lastName: res.data.last_name,
        userType: res.data.user_type as UserType,
        role: res.data.role as Role,
        agreedToTos: res.data.agreed_to_tos,
      };
    },
  });

export const useUpdateUserApiV1UsersUserIdMutation = () => {
  return useMutation({
    mutationFn: (payload: { userId: string; data: UserUpdate }) => {
      return userApi.updateUserApiV1UsersUserIdPatch(payload.userId, payload.data);
    },
    onSuccess: (res, req) => {
      queryClient.setQueryData(['users', 'detail', req.userId], res.data);
    },
  });
};

export const useAddFleetManagerApiV1CompaniesCompanyIdFleetsPostMutation = () => {
  return useMutation({
    mutationFn: (payload: { companyId: string; data: UserCreate }) => {
      return userApi.addFleetManagerApiV1CompaniesCompanyIdFleetsPost(payload.companyId, payload.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['companies', 'fleetManagers'] });
    }
  });
};

export const useListNotificationsByUserApiV1UsersSelfNotificationsQuery = (
  params: ListNotificationsByUserApiV1UsersSelfNotificationsGetParams
) =>
  useQuery({
    queryKey: ['notifications'],
    queryFn: async () => {
      const res = await userApi.listNotificationsByUserApiV1UsersSelfNotificationsGet(params);
      return res.data;
    },
  });

export const useMarkNotificationAsReadApiV1NotificationsNotificationIdReadMutation = () => {
  return useMutation({
    mutationFn: (notificationId: string) => {
      return userApi.markNotificationAsReadApiV1NotificationsNotificationIdReadPost(notificationId);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
};

// agreeToTosSelfApiV1UsersSelfTosAgreedPost
export const useAgreeToTosSelfApiV1UsersSelfTosAgreedMutation = () => {
  return useMutation({
    mutationFn: () => {
      return userApi.agreeToTosSelfApiV1UsersSelfTosAgreedPost();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users', 'self'] });
    },
  });
};

// listFleetManagersByCompanyApiV1CompaniesCompanyIdFleetsGet
export const useListFleetManagersByCompanyApiV1CompaniesCompanyIdFleetsQuery = <TData>(
  params: ListFleetManagersByCompanyApiV1CompaniesCompanyIdFleetsGetParams,
  options?: Omit<
    UseQueryOptions<ListFleetManagersByCompanyApiV1CompaniesCompanyIdFleetsGetData, unknown, TData>,
    'queryKey'
  >
) =>
  useQuery<ListFleetManagersByCompanyApiV1CompaniesCompanyIdFleetsGetData, unknown, TData>({
    queryKey: ['companies', 'fleetManagers', params],
    queryFn: async () => {
      const res = await userApi.listFleetManagersByCompanyApiV1CompaniesCompanyIdFleetsGet(params);
      return res.data;
    },
    ...options,
  });
