import { useMutation, useQuery } from '@tanstack/react-query';
import { http, queryClient } from 'lib/ajax/axios';
import { LineTalk } from 'swagger/LineTalk';
import { parseLineTalkPost, parseLineTalkThread } from './model';
import {
  BodyUploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsPost,
  LineTalkPostCreate,
  LineTalkThreadCreate,
  ListLinetalkPostsApiV1LinetalksThreadIdMessagesGetParams,
  ListLinetalkThreadsApiV1LinetalksGetParams,
} from 'swagger/data-contracts';

const linetalkApi = new LineTalk(http);

export const useListLinetalksApiV1LinetalksQuery = (request: ListLinetalkThreadsApiV1LinetalksGetParams) => {
  return useQuery({
    queryKey: ['linetalks', 'list', request],
    queryFn: async () => {
      const res = await linetalkApi.listLinetalkThreadsApiV1LinetalksGet(request);
      return res.data;
    },
    select: data => ({
      totalCount: data.total_count,
      list: data.threads.map(parseLineTalkThread),
    }),
  });
};

export const useGetLinetalkThreadApiV1LinetalksThreadIdQuery = (threadId: string) => {
  return useQuery({
    queryKey: ['linetalks', threadId],
    queryFn: async () => {
      const res = await linetalkApi.getLinetalkThreadApiV1LinetalksThreadIdGet(threadId);
      return res.data;
    },
    select: parseLineTalkThread,
  });
};

export const useInviteDsrStaffToThreadApiV1LinetalksThreadIdInvitesMutation = (threadId: string) => {
  return useMutation({
    mutationFn: async (threadId: string) => {
      return await linetalkApi.inviteDsrStaffToThreadApiV1LinetalksThreadIdInvitesPost(threadId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['linetalks', threadId] });
    },
  });
};

//startLinetalkApiV1LinesLineIdLinetalksPost
export const useListLinetalkPostsApiV1LinetalksThreadIdMessagesQuery = (
  request: ListLinetalkPostsApiV1LinetalksThreadIdMessagesGetParams
) => {
  return useQuery({
    queryKey: ['linetalks', request.threadId, 'messages'],
    queryFn: async () => {
      const res = await linetalkApi.listLinetalkPostsApiV1LinetalksThreadIdMessagesGet(request);
      return res.data;
    },
    select: data => {
      const loadedPost = data.posts.map(parseLineTalkPost).reverse();
      const totalCount = data.total_count;

      const isOlderPost = data.offset > 0;

      return {
        totalCount,
        loadedPost,
        isOlderPost,
        offset: data.offset,
      };
    },
    refetchInterval: 3000,
  });
};

export const useStartLinetalkApiV1LinesLineIdLinetalksMutation = (threadId: string) => {
  return useMutation({
    mutationFn: async (payload: { lineId: string; data: LineTalkThreadCreate }) => {
      return await linetalkApi.startLinetalkApiV1LinesLineIdLinetalksPost(payload.lineId, payload.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['linetalks', 'list'] });
      queryClient.invalidateQueries({ queryKey: ['linetalks', threadId, 'messages'] });
    },
  });
};

// addMessageToThreadApiV1LinetalksThreadIdMessagesPost
export const useAddMessageToThreadApiV1LinetalksThreadIdMessagesMutation = (threadId: string) => {
  return useMutation({
    mutationFn: async (payload: { threadId: string; data: LineTalkPostCreate }) => {
      return await linetalkApi.addMessageToThreadApiV1LinetalksThreadIdMessagesPost(payload.threadId, payload.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['linetalks', threadId, 'messages'] });
    },
  });
};

//uploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsPost
export const useUploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsMutation = (threadId: string) => {
  return useMutation({
    mutationFn: async (payload: {
      threadId: string;
      data: BodyUploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsPost;
    }) => {
      return await linetalkApi.uploadAttachmentsToThreadApiV1LinetalksThreadIdAttachmentsPost(
        payload.threadId,
        payload.data
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['linetalks', threadId, 'messages'] });
    },
  });
};
