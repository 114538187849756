import * as LabelPrimitive from '@radix-ui/react-label';
import { FormEvent, ReactNode } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { styled } from 'theme/stitches.config';
import { Icon, IconVariant } from '../Icon';
import { Body1, createBody3Style } from '../Typography';

export const Label = styled(LabelPrimitive.Root, {
  color: '$gray700',
  fontSize: '$body3',
  fontFamily: '$medium',
  fontWeight: '$medium',
  userSelect: 'none',
  variants: {
    block: {
      true: {
        display: 'block',
        marginBottom: '4px',
      },
      false: {
        paddingRight: '8px',
      }
    },
    required: {
      true: {
        '&::before': {
          content: '"*"',
          color: '$remove',
          marginRight: '2px',
        },
      },
    }
  },
  defaultVariants: {
    block: false,
    required: false,
  }
});
export const TextField = styled('input', {
  border: '1px solid $gray900',
  color: '$black',

  fontSize: '$body1',
  fontFamily: '$medium',
  fontWeight: '$medium',

  '&:focus': { boxShadow: `$fieldActive`, outline: 'none' },
  '&:disabled': { border: '1px solid $gray500', color: '$gray700' },
  variants: {
    size: {
      large: {
        padding: '12px 16px',
        height: '56px',
      },
      medium: {
        padding: '8px 12px',
        height: '40px',
      },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});
export const TextArea = styled('textarea', {
  border: '1px solid $gray300',
  color: '$black',
  resize: 'none',

  fontSize: '$body3',
  fontFamily: '$regular',
  fontWeight: '$regular',
  padding: '8px 12px',

  '&:focus': { boxShadow: `$fieldActive`, outline: 'none' },
  '&:disabled': { border: '1px solid $gray500', color: '$gray700' },

  defaultVariants: {
    size: 'medium',
  },
});

const StyledTextFieldBox = styled('form', {
  position: 'relative',
  width: 280,
  height: 40,
  input: {
    width: '100%',
  },
  button: {
    position: 'absolute',
    right: 0,
    width: 48,
    height: 40,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
});
const StyledTextField = styled(TextField, {
  position: 'absolute',
  paddingRight: 48,
});
export const ActionTextField = ({ icon, onSubmit }: { icon: IconVariant; onSubmit: (keyword: string) => void }) => {
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const keyword = ((e.target as HTMLFormElement).elements[0] as HTMLInputElement).value;
    onSubmit(keyword);
  };
  return (
    <StyledTextFieldBox onSubmit={handleSubmit}>
      <StyledTextField />
      <button type='submit'>
        <Icon variant={icon} width='32' height='32' />
      </button>
    </StyledTextFieldBox>
  );
};
const StyleError = styled('p', {
  color: 'red',
  variants: {
    error: {
      true: {
        display: 'block',
      },
      false: {
        display: 'none',
      },
    },
  },
});
export const ErrorMessage = ({ errors }: { errors?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> }) => (
  <StyleError error={!!errors} className={createBody3Style({ weight: 'regular' })}>
    {String(errors?.message)}
  </StyleError>
);
export const FormTitle = ({ children }: { children: ReactNode }) => (
  <Body1 weight='bold' css={{}}>
    {children}
  </Body1>
);
export const Form = styled('form', {
  marginTop: 24,
});
export const FormDescription = styled('div', {
  backgroundColor: '$gray100',
  padding: 24,
  marginTop: 24,
  '> div+div': {
    marginTop: 12,
  },
});
export const FormControl = styled('div', {
  variants: {
    margin: {
      large: {
        marginTop: 32,
      },
      medium: {
        marginTop: 20,
      },
    },
  },
  defaultVariants: {
    margin: 'medium',
  },
  // display:'flex'
});
export const FormDivider = styled('div', {
  height: 32,
  width: '100%',
});
export const FormFooter = styled('div', {
  display: 'flex',
  variants: {
    margin: {
      large: {
        marginTop: 48,
      },
      medium: {
        marginTop: 24,
      },
    },
    align: {
      left: {
        justifyContent: 'left',
      },
      center: {
        justifyContent: 'center',
      },
      right: {
        justifyContent: 'right',
      },
    },
  },
  defaultVariants: {
    margin: 'medium',
    align: 'left',
  },
});
export const SubmitButton = styled('button', {
  backgroundColor: '$primary',
  color: '$white',
  padding: '16px 24px',
  height: 64,
  fontSize: '$button',
  fontFamily: '$semibold',
  fontWeight: '$semibold',
  '&:hover': {
    backgroundColor: '$buttonLoginHover',
  },
  '&:disabled': {
    backgroundColor: '$buttonLoginHover',
  },
});
export const LoadingGlass = styled('div', {
  position: 'absolute',
  backgroundColor: '$white',
  opacity: 0.7,
  top: 0,
  width: '100%',
  height: '100%',
});
