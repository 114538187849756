import { Drawer, drawerState, DRAWER_ID } from 'components/common/Drawer';
import { Icon } from 'components/common/Icon';
import { Body1, Body2 } from 'components/common/Typography';
import { useLinetalkThreadInfo } from 'components/LineTalk/atoms';
import { Lines } from 'components/LineTalk/Lines';
import { Thread } from 'components/LineTalk/Thread';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { styled } from 'theme/stitches.config';

const Container = styled('div', {
  height: '100%',
  width: 280,
  padding: '20px 0',
  background: 'white',
  display: 'flex',
  flexDirection: 'column',
});
const Header = styled('div', {
  height: 48,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 16px',
});
const ActionBox = styled('div', {
  cursor: 'pointer',
  '&:hover': {
    color: '$blue300',
    path: {
      stroke: '$blue300',
    },
  },
  span: {
    marginLeft: 8,
  },
});
const Content = styled('div', {
  flex: 1,
});

const LineTalk = () => {
  const [selectedLine, setSelectedLine] = useLinetalkThreadInfo();

  const handleSelect = (lineId: string, customId: string) => {
    setSelectedLine({ lineId, customId });
  };
  const handleBackToList = () => {
    setSelectedLine({ lineId: '', customId: '' });
  };

  const [drawer] = useRecoilState(drawerState);

  useEffect(() => {
    if (drawer && !drawer['LINE_TALK'].isOpened) {
      setSelectedLine({ lineId: '', customId: '' });
    }
  }, [drawer, setSelectedLine]);



  return (
    <Drawer id={DRAWER_ID.LINE_TALK} direction='left'>
      <Container>
        <Header>
          <Body1 weight='bold'>Line Talk</Body1>
          {selectedLine.lineId && (
            <ActionBox onClick={handleBackToList}>
              <Icon variant='arrowBack' />
              <Body2 weight='regular'>Back to the list</Body2>
            </ActionBox>
          )}
        </Header>
        <Content>{selectedLine.lineId ? <Thread {...selectedLine} /> : <Lines onSelect={handleSelect} />}</Content>
      </Container>
    </Drawer>
  );
};

export default LineTalk;
