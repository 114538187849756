import { useToast } from 'components/common/Toast/ErrorToast';
import HttpStatusCode from 'lib/ajax/statusCodes';
import { useEffect } from 'react';
import { useLinetalkThreadInfo } from './atoms';
import {
  useGetLinetalkThreadApiV1LinetalksThreadIdQuery,
  useInviteDsrStaffToThreadApiV1LinetalksThreadIdInvitesMutation,
} from 'services/linetalk/queries';


// NOTE: Actually, LineID and ThreadId are same.
export const useThreadContoller2 = (threadId: string) => {
  
  const { render } = useToast();
  const [{ isNewLinetalk }, setLinetalkThreadInfo] = useLinetalkThreadInfo();

  const detailApi = useGetLinetalkThreadApiV1LinetalksThreadIdQuery(threadId);
  const inviteDSRStaff = useInviteDsrStaffToThreadApiV1LinetalksThreadIdInvitesMutation(threadId);

  const threadData = detailApi.data || { id: '', includeDsr: false };

  useEffect(() => {
    if (detailApi.status === 'success') {
      setLinetalkThreadInfo(prev => ({ ...prev, isNewLinetalk: false }));
    } else {
      setLinetalkThreadInfo(prev => ({ ...prev, isNewLinetalk: true }));
    }
  }, [detailApi.status, setLinetalkThreadInfo]);

  const onRequestInviteDSRStaff = async () => {
    if (window.confirm('Are you sure to invite DSR staff?')) {
      const res = await inviteDSRStaff.mutateAsync(threadId);
      if (res.status === HttpStatusCode.ACCEPTED) {
        render({ message: 'DSR staff has been invited.', type: 'success' });
      } else {
        render({ message: 'The invitation failed. Please try again in a moment.', type: 'error' });
      }
    }
  };

  return {
    ...detailApi,
    data: threadData,
    isNewLinetalk,
    onRequestInviteDSRStaff,
  };
};
