import { createStitches, VariantProps, CSS } from '@stitches/react';

export type { VariantProps, CSS };

export const COLOR_TOKENS = {
  white: '#FFFFFF',
  black: '#0000000',
  mooring: '#00906E',
  remove: '#CD0202',
  primary: '#0C1B6E',
  
  gray100: '#F8F8F8',
  gray200: '#F2F2F2',
  gray300: '#DBDBDB',
  gray400: '#CDCDCD',
  gray500: '#B9B9B9',
  gray600: '#949494',
  gray700: '#808080',
  gray800: '#535353',
  gray900: '#444444',
  buttonHover: 'rgba(68,68,68,0.80)',
  dim: 'rgba(0,0,0,0.70)',
  buttonTalk: '#BDE3FF',

  buttonTalkHover: 'rgba(189,227,255,0.50)',
  blue100: '#E8F1FF',
  blue200: '#7EB1FF',
  blue300: '#0C6CFF',
  buttonInspect: '#C6ECC6',
  buttonDetach: '#FFDADA',
  buttonDetachHover: 'rgba(255,218,218,0.50)',
  buttonReverse: '#FFE8A3',
  buttonReverseHover: 'rgba(255,232,163,0.50)',
  buttonAttach: '#E4CCFF',
  buttonAttachHover: 'rgba(228,204,255,0.50)',
  buttonMooringHover: 'rgba(0,144,110,0.80)',
  buttonSwitch: '#E5F6E5',
  buttonSwitchHover: 'rgba(229,246,229,0.50)',

  gnbHover: '#33568F',
  buttonLoginHover: 'rgba(12,27,110,0.80)',
  fleet: '#6C12B2',
  bgFleet: '#F1E8FF',
  line: '#FFA629',
  bgLine: '#FFF4D1',

  // undefined colors
  buttonSubmit:'#2B40B0',
  borderToastSuccess: 'rgb(76, 175, 80)',
  txtToastSuccess: 'rgb(30, 70, 32)',

  borderToastError: 'rgb(239, 83, 80)', 
  txtToastError: 'rgb(95, 33, 32)',

  borderToastInfo: 'rgb(3, 169, 244)',
  txtToastInfo: 'rgb(1, 67, 97)',

  borderToastWarning: 'rgb(255, 152, 0)', 
  txtToastWarning: 'rgb(102, 60, 0)',

  buttonDisabled: '#CDCDCD', // disabled


  /// new colors
  lightOrange: '#F4E7DA',
  lightBlue: '#D1F1FF',
  lightPink: '#F8D7DA',
  paleGreen: '#E5F6E5',

}


export const { styled, css, keyframes } = createStitches({
  theme: {
    colors: COLOR_TOKENS,
    space: {
      1: '5px',
      2: '10px',
      3: '15px',
    },
    fontSizes: {
      
      index: '40px',
      subtitle: '14px',
      button: '20px',

      logo: '24px',
      page1: '32px',
      page2: '20px',
      page3: '18px',
      body1: '16px',
      body2: '14px',
      body3: '12px',
      date: '10px'
    },
    fonts: {
      regular: 'Poppins-Regular',
      medium: 'Poppins-Medium',
      semibold: 'Poppins-SemiBold'
    },
    fontWeights: {
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
    lineHeights: {
      default: 1.5
    },
    letterSpacings: {},
    sizes: {},
    borderWidths: {},
    borderStyles: {},
    radii: {},
    shadows: {
      fieldActive: '0px 4px 10px rgba(100, 100, 100, 0.25)'
    },
    zIndices: {
      toast: 999,
      modal: 800,
      alertBackground: 1000,
      alertContent: 1001
    },
    transitions: {},
  },
});