import HttpStatusCode from 'lib/ajax/statusCodes';
import { STUB_LINES_BY_VESSEL_ID } from 'mocks/stubs/lines';
import { resolveEndpoint, sleep } from 'mocks/utils';
import { http, HttpResponse } from 'msw';

export const getLinesByVesselId = http.get(resolveEndpoint(`api/v1.1/vessels/:vesselId/lines/`), () => {
  return HttpResponse.json(STUB_LINES_BY_VESSEL_ID);
});
export const updateBroughtInHours = http.patch(
  resolveEndpoint(`api/v1.2/vessels/:vesselId/lines/brought_in_hours/`),
  async ({ request }) => {
    await sleep(3000);
    const body = await request.json();

    console.log('updateBroughtInHours body: ', body);

    return HttpResponse.json({ status: HttpStatusCode.OK });
  }
);
