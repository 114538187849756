import { CheckIcon, UploadIcon } from '@radix-ui/react-icons';
import { ChangeEvent, forwardRef, ForwardRefRenderFunction, ReactNode, useMemo, useState } from 'react';
import { styled } from 'theme/stitches.config';

type Props = {
  id?: string;
  defaultFile?: File[];
  children: ReactNode;
  multiple?: boolean;
  disabled?: boolean;
  onChange: (files: File[]) => void;
};

const UploaderButton = styled('span', {
  fontSize: '$body2',
  fontFamily: '$regular',
  fontWeight: '$regular',
  padding: '8px 16px',
  minHeight: '40px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    marginRight: '8px',
  },
  wordBreak: 'break-word',
});
const ButtonLabel = styled('label', {
  cursor: 'pointer',
  display: 'inline-block',
  width: '100%',
  border: '1px solid $gray900',
  minHeight: 40,
  variants: {
    disabled: {
      true: {
        opacity: 0.3,
        pointerEvents: 'none',
      },
      false: {
        opacity: 1,
        pointerEvents: 'all',
      },
    },
  }
});

const FILE_UPLOADER_DEFAULT_ID = 'contained-button-file';
const FileUploaderComponent: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { id, children, onChange, disabled, defaultFile, ...props },
  ref
) => {
  const [selectedFile, setSelectedFile] = useState<File[]>(defaultFile as File[]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const files = Array.from(event.currentTarget?.files || []);
    setSelectedFile(files);
    onChange(files);
  };

  const fullFileNames = useMemo(() => {
    return selectedFile?.reduce((acc, curr) => `${acc ? acc + ',' : ''} ${curr.name}`, '');
  }, [selectedFile]);
  const fileNameLabel = useMemo(() => {
    if (selectedFile) {
      if (selectedFile.length === 1) return selectedFile[0].name;
      else return `${selectedFile[0].name}${selectedFile.length > 1 ? ` and ${selectedFile.length - 1} files` : ''}`;
    }
  }, [selectedFile]);

  const FILE_UPLOADER_ID = id || FILE_UPLOADER_DEFAULT_ID;

  return (
    <ButtonLabel disabled={disabled} htmlFor={FILE_UPLOADER_ID} title={fullFileNames}>
      <UploaderButton>
        {!!selectedFile ? <CheckIcon /> : <UploadIcon />}
        <div>{!!selectedFile ? fileNameLabel : children}</div>
      </UploaderButton>
      <input
        type='file'
        // accept='image/*'
        style={{ display: 'none' }}
        id={FILE_UPLOADER_ID}
        {...props}
        onChange={handleChange}
        ref={ref}
      />
    </ButtonLabel>
  );
};
export const FileUploader = forwardRef(FileUploaderComponent);
