import { useMemo } from 'react';
import { styled } from 'theme/stitches.config';
import { createBody1Style } from '../Typography';
import usePagination from './usePagination';

const PaginationButton = styled('button', {
  all: 'unset',
  display: 'inline-block',
  height: 22,
  padding: '16px',
  '&+&': {
    marginLeft: '16px',
  },

  variants: {
    isActive: {
      true: {
        color: '$blue300',
      },
      false: {
        color: '$black',
      },
    },
  },
});

const PaginationArrowButton = styled('button', {
  all: 'unset',
  display: 'inline-block',
  height: 24,

  variants: {
    disabled: {
      true: {
        svg: {
          verticalAlign: 'middle',
          fill: '$gray300',
        },
      },
      false: {
        svg: {
          verticalAlign: 'middle',
          fill: '$gray900',
        },
      },
    },
  },
  '&:first-child': {
    marginRight: '36px',
  },
  '&:last-child': {
    marginLeft: '36px',
  },
});

const PaginationContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 80,
});
// parseInt(Number(11 / 10).toFixed(0)

export const Pagination = ({
  disabled,
  totalCount,
  pageSize,
  defaultPage,
  onChange: _onChange,
}: {
  disabled?: boolean;
  totalCount: number;
  defaultPage: number;
  pageSize: number;
  onChange: (currentPageNumber: number) => void;
}) => {
  const pageCount = useMemo(() => Math.ceil(totalCount / pageSize), [totalCount, pageSize]);
  const { current, pages, onChange } = usePagination({
    _total: pageCount,
    onChange: _onChange,
    _defaultCurrent: defaultPage,
  });
  const headPage = pages[0];
  const lastPage = pages[pages.length - 1];

  const isPrevAvailable = useMemo(() => disabled || headPage === 1, [disabled, headPage]);
  const isNextAvailable = useMemo(() => disabled || lastPage >= pageCount, [disabled, lastPage, pageCount]);

  const handlePrevClick = () => {
    if (isPrevAvailable) return;
    else onChange(headPage - 1);
  };
  const handleNextClick = () => {
    if (isNextAvailable) return;
    else onChange(lastPage + 1);
  };

  return (
    <PaginationContainer>
      {totalCount > 0 && (
        <>
          <PaginationArrowButton
            disabled={isPrevAvailable}
            onClick={handlePrevClick}
            title={`Go to page ${headPage - 1}`}
          >
            <PagePrev />
          </PaginationArrowButton>
          {pages.map(page => (
            <PaginationButton
              disabled={disabled}
              isActive={page === current}
              key={page}
              onClick={() => onChange(page)}
              title={`Go to page ${page}`}
              className={createBody1Style({ weight: 'regular' })}
            >
              {page}
            </PaginationButton>
          ))}
          <PaginationArrowButton
            disabled={isNextAvailable}
            onClick={handleNextClick}
            title={`Go to page ${lastPage + 1}`}
          >
            <PageNext />
          </PaginationArrowButton>
        </>
      )}
    </PaginationContainer>
  );
};

const PagePrev = () => (
  <svg width='16' height='16' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.766 14.45a.846.846 0 0 1-1.132 0l-6.4-5.926a.703.703 0 0 1 0-1.048l6.4-5.926a.846.846 0 0 1 1.132 0 .703.703 0 0 1 0 1.048L5.93 8l5.835 5.402a.703.703 0 0 1 0 1.048Z'
    />
  </svg>
);

const PageNext = () => (
  <svg width='16' height='16' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.234 1.55a.846.846 0 0 1 1.132 0l6.4 5.926a.703.703 0 0 1 0 1.048l-6.4 5.926a.846.846 0 0 1-1.132 0 .703.703 0 0 1 0-1.048L10.07 8 4.234 2.598a.703.703 0 0 1 0-1.048Z'
    />
  </svg>
);
