import { useState, useEffect, useMemo } from 'react';

export type usePaginationProps = {
  _current?: number;
  _defaultCurrent?: number;
  _total: number;
  disabled?: boolean;
  onChange?: (newCurrent: number) => void;
};

const PAGES_IN_EACH = 5;

const usePagination = ({
  _current,
  _defaultCurrent = 1,
  _total,
  disabled,
  onChange: _onChange,
}: usePaginationProps): {
  current: number;
  pages: number[];
  onChange: (newCurrent: number) => void;
} => {
  const [current, setCurrentPage] = useState(Math.max(_current || _defaultCurrent, 1));
  /* eslint-disable react-hooks/exhaustive-deps */
  const pages = useMemo(() => {
    // These are the first page and the last page in this section
    const firstPage = Math.floor((current - 1) / PAGES_IN_EACH) * PAGES_IN_EACH + 1;
    const lastPage = Math.min(_total + 1, firstPage + PAGES_IN_EACH);

    return range(firstPage, lastPage);
  }, [Math.floor((current - 1) / PAGES_IN_EACH), _total]);
  /* eslint-enable react-hooks/exhaustive-deps */

  if (_total < 0) {
    console.error('Should put positive number for total')
  }

  useEffect(() => {
    if (_current && _current !== current) {
      setCurrentPage(_current);
    }
  }, [_current, current]);

  const onChange = (newCurrent: number): void => {
    if (!disabled) {
      setCurrentPage(newCurrent);
    }

    if (_onChange && typeof _onChange === 'function') {
      _onChange(newCurrent);
    }
  };

  return {
    current,
    pages,
    onChange,
  };
};

function range(start: number, end: number) {
  if (Number.isNaN(Number(start)) || Number.isNaN(Number(end))) {
    throw new TypeError('Both arguments to range must be numbers');
  }

  if (end < start) return [];

  const len = end - start;
  const willReturn = Array(len);

  for (let i = 0; i < len; i++) {
    willReturn[i] = start + i;
  }

  return willReturn;
}

export default usePagination;
