import { styled } from 'theme/stitches.config';
import { Icon } from '../Icon';

const Box = styled('div', {
  width: '100%',
  height: '100%',
  minHeight: 500,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  svg: {
    stroke: '$blue200',
  },
});

export const PageLoader = () => (
  <Box>
    <Icon variant='loading' strokeWidth={10} width={60} height={60} />
  </Box>
);

const OverlayLoaderBox = styled('div', {
  position: 'relative',

});
export const ComponentOverlayLoader = () => (
  <OverlayLoaderBox>
    <Icon variant='loading' strokeWidth={10} width={60} height={60} />
  </OverlayLoaderBox>
);

export const TableLoader = ({ colSpan = 1 }: { colSpan?: number }) => (
  <tr>
    <td colSpan={colSpan}>
      <Box>
        <Icon variant='loading' strokeWidth={10} width={60} height={60} />
      </Box>
    </td>
  </tr>
);

const TableOverlayLoaderRow = styled('tr', {
  position: 'relative',
});
const TableOverlayLoaderCell = styled('td', {
  position: 'absolute',
  width: '100% !important',
  background: 'rgba(255,255,255,.7)',
});
export const TableOverlayLoader = ({ colSpan = 1 }: { colSpan?: number }) => (
  <TableOverlayLoaderRow>
    <TableOverlayLoaderCell colSpan={colSpan}>
      <Box>
        <Icon variant='loading' strokeWidth={10} width={60} height={60} />
      </Box>
    </TableOverlayLoaderCell>
  </TableOverlayLoaderRow>
);
