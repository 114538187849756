import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';
import { styled } from 'theme/stitches.config';

const OutlinedButton = styled('button', {
  backgroundColor: '$white',
  color: '$gray900',
  border: '1px solid $gray900',
  padding: '8px 16px',

  fontSize: '$body2',
  fontFamily: '$regular',
  fontWeight: '$regular',
  '&:hover': {
    opacity: '.5',
  },

  '&:disabled': {
    border: '1px solid $buttonDisabled',
    color: '$buttonDisabled',
    '&:hover': {
      opacity: '1',
    },
  },

  variants: {
    color: {
      primary: {
        color: '$gray900',
      },
      green: {
        color: '$mooring',
      },
      gray: {
        color: '$gray900',
      },
      lightGray: {
        color: '$gray400',
        border: '1px solid $gray400',
      }
    },
    fluid: {
      true: {
        width: '100%',
      },
    },
    size: {
      small: {
        padding: '4px 8px',
      },
      medium: {
        padding: '8px 16px',
      },
    },
  },
  defaultVariants: {
    color: 'primary',
    fluid: false,
    size: 'medium',
  },
});

const ContainedButton = styled('button', {
  backgroundColor: '$buttonSubmit',
  color: '$white',
  border: '1px solid $buttonSubmit',
  padding: '8px 16px',

  fontSize: '$body2',
  fontFamily: '$regular',
  fontWeight: '$regular',


  transition: 'opacity 0.1s ease-in-out',

  '&:hover': {
    opacity: '.5',
  },

  '&:disabled': {
    border: '1px solid $buttonDisabled',
    backgroundColor: '$buttonDisabled',
    '&:hover': {
      opacity: '1',
    },
  },

  variants: {
    color: {
      primary: {
        backgroundColor: '$buttonSubmit',
        border: '1px solid $buttonSubmit',
      },
      green: {
        backgroundColor: '$mooring',
        border: '1px solid $mooring',
      },
      gray: {
        backgroundColor: '$gray900',
        border: '1px solid $gray900',
      },
      lightGray: {
        backgroundColor: '$gray400',
        border: '1px solid $gray400',
      }
    },

    fluid: {
      true: {
        width: '100%',
      },
    },
    size: {
      small: {
        padding: '4px 8px',
      },
      medium: {
        padding: '8px 16px',
      },
    },
  },
  defaultVariants: {
    color: 'primary',
    fluid: false,
    size: 'medium',
  },
});

type Props = {
  variant: 'contained' | 'outlined';
  color?: 'primary' | 'green' | 'gray' | 'lightGray';
  fluid?: boolean;
  size?: 'small' | 'medium';
  children: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;
const resolveComponent = (variant: Props['variant']) => {
  if (variant === 'contained') return ContainedButton;
  else return OutlinedButton;
};
export const Button = forwardRef<HTMLButtonElement, Props>(({ variant, children, ...props }, forwardedRef) => {
  const Component = resolveComponent(variant);
  return (
    <Component {...props} ref={forwardedRef}>
      {children}
    </Component>
  );
});

export const IconButton = styled('button', {
  all: 'unset',
  fontFamily: 'inherit',
  borderRadius: '100%',
  height: 25,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 10,
  right: 10,

  '&:hover': {
    opacity: '.5',
  },

  // '&:hover': { backgroundColor: violet.violet4 },
  // '&:focus': { boxShadow: `0 0 0 2px ${violet.violet7}` },
});
