import { styled } from "theme/stitches.config";


export const Box = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});
export const Header = styled('div', {
  backgroundColor: '$gray200',
  padding: '12px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: 48,
  marginBottom: 4,
});


export const NewMessageForm = styled('div', {
  padding: '12px 16px',
  width: '100%',
  display: 'flex',
  button: {
    marginLeft: 8,
  },
});

export const FileUpload = styled('div', {
  borderTop: '1px solid $gray300',
  borderBottom: '1px solid $gray300',
  label: {
    border: 0,
    color: '$gray900',
  },
});

export const IconBox = styled('div', {
  width: 40,
  height: 40,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  ' img': {
    width: 27,
    height: 27,
  },
  backgroundColor: '$blue100',
  svg: {
    fill: '$blue200',
  },
});


export const Content = styled('div', {
  padding: '12px 16px',
  overflow: 'auto',
  height: 'calc(100vh - 320px)',
  scrollBehavior: 'smooth',
});

export const Post = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  wordBreak: 'break-word',

  variants: {
    isMine: {
      true: {
        alignItems: 'flex-end',
        'div > div': {
          background: '$blue100',
          marginLeft: 8,
        },
        span: {
          textAlign: 'right',
        },
      },
      false: {
        alignItems: 'flex-start',
        'div > div': {
          background: '$gray100',
          marginRight: 8,
        },
      },
    },
  },

  '&  + &': {
    marginTop: 8,
  },

  div: {
    display: 'flex',
    alignItems: 'flex-end',
  },

  'a[role="button"]': {
    color: '$blue300',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  'svg > path': {
    fill: '$blue300',
  },
});

export const Message = styled('div', {
  width: 178,
  marginTop: 4,
  padding: '12px 16px',
  borderRadius: 8,
});

