import { WinsAdminAxios, winsAdminAxios } from 'lib/ajax/axios';
import { atom, useRecoilState } from 'recoil';

export const axiosState = atom<WinsAdminAxios | null>({
  key: 'axiosState',
  default: winsAdminAxios,
});

// set: (ticker: string) => ({ set }, newValue) => {
//   if (newValue instanceof DefaultValue) return newValue
//   set(assetDetailsState, (prevState) => ({
//     ...prevState,
//     [ticker]: newValue,
//   }))
// },

export const useAxiosState = () => useRecoilState(axiosState);
