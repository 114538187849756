import { Token } from 'services/auth/model';
import Cookies from 'js-cookie';
import { User } from 'services/user/model';

const key = 'USER';

export const userStorage = {
  get() {
    const data = Cookies.get(key) || localStorage.getItem(key);
    try {
      if (!data) return null;
      const parsed = JSON.parse(data) as User;
      return parsed;
    } catch (e) {
      localStorage.removeItem(key);
      return null;
    }
  },
  set(user: User) {
    Cookies.set(key, JSON.stringify(user), { expires: 7 });
    localStorage.setItem(key, JSON.stringify(user));
  },
  clear() {
    Cookies.remove(key);
    localStorage.removeItem(key);
  },
};

const TOKEN_KEY = 'WINS-TOKEN';
export const tokenStorage = {
  get(): Token | null {
    const data = Cookies.get(TOKEN_KEY) || localStorage.getItem(TOKEN_KEY);
    try {
      if (!data) return null;
      const parsed = JSON.parse(data) as Token;
      return parsed;
    } catch (e) {
      localStorage.removeItem(TOKEN_KEY);
      return null;
    }
  },
  set(token: Token) {
    Cookies.set(TOKEN_KEY, JSON.stringify(token), { expires: 7 });
    localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
  },
  clear() {
    Cookies.remove(TOKEN_KEY);
    localStorage.removeItem(TOKEN_KEY);
  },
};
