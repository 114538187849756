import { atom } from 'recoil';

export const notificactionAtom = atom({
  key: 'notificationAtom',
  default: {
    totalCount: 0,
    notifications: [] as {
      id: string; //'3ac9b00c-3ef7-4c5e-a97c-120634992338';
      is_read: boolean; //false;
      context: string; //DEMO-LINE-0';
      type_name: string; //'New Linetalk';
      message: string; //'message sent'; }[],
    }[],
  },
});
