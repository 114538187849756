import { userStorage } from 'lib/userStorage';
import { parseDateTime } from 'lib/utils';
import { UUID } from 'services/schema';
import { LineTalkPostRead, LineTalkThreadRead } from 'swagger/data-contracts';

export type LineTalkCreateForm = {
  subject: string;
  message: string;
  recipientIds: string[]; // user ids
};

export type LineTalk = {
  id: string;
  subject: string;
  authorId: string;
};

export type LineTalkThread = {
  id: UUID;
  includeDsr: boolean;
  lastActivity: string;
  line: undefined | {
    id: UUID;
    customId?: string;
    meg4Id: string;
  }
};

export type LineTalkPost = {
  id: UUID;
  message: string;
  attachments: {
    id: string;
    url: string;
    filename: string;
  }[];
  createdAt: string;
  isMine: boolean;
  author: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
  };
};

export type StartLineTalkForm = LineTalkCreateForm;

export type LineTalkMessageForm = { message: string };

export const parseLineTalkThread = (data: LineTalkThreadRead): LineTalkThread => ({
  id: data.id,
  includeDsr: data.include_dsr,
  lastActivity: parseDateTime(data.last_activity),
  line: data.line && {
    id: data.line.id,
    customId: data.line.custom_id,
    meg4Id: data.line.meg4_id,
  }
});

export const parseLineTalkPost = (raw: LineTalkPostRead): LineTalkPost => {
  const userId = userStorage.get();
  return {
    id: raw.id,
    message: raw.message || '',
    attachments: raw.attachments.map(att => ({
      id: att.id,
      url: att.attachment_url,
      filename: att.filename,
    })),
    createdAt: parseDateTime(raw.created_at),
    isMine: userId?.id === raw.author.id,
    author: {
      id: raw.author.id,
      email: raw.author.email,
      firstName: raw.author.first_name,
      lastName: raw.author.last_name,
    },
  };
};
