import { atom, useRecoilState } from 'recoil';
import { LineTalkPost } from 'services/linetalk/model';

const linetalThreadInfoState = atom<{ lineId: string; customId: string; isNewLinetalk?: boolean }>({
  key: 'linetalThreadInfoState',
  default: {
    lineId: '',
    customId: '',
    isNewLinetalk: true, // 한 뷰에서 isNewLinetalk false에서 true로 바뀌기 때문에 상태관리가 필요함.
  },
});
export const useLinetalkThreadInfo = () => useRecoilState(linetalThreadInfoState);

export const linetalkPostsState = atom({
  key: 'linetalkPostsState',
  default: {
    offset: 0,
    limit: 10,
    totalCount: 0,
    list: [] as LineTalkPost[],
    hasNext: false,
    firstMessageId: '',
    lastMessageId: '',
  },
});
export const useLinetalkPostsState = () => useRecoilState(linetalkPostsState);
export const useClearLinetalkPostsState = () => {
  const [, setLinetalkPostsState] = useLinetalkPostsState();
  return () =>
    setLinetalkPostsState({
      offset: 0,
      limit: 10,
      totalCount: 0,
      list: [],
      hasNext: false,
      firstMessageId: '',
      lastMessageId: '',
    });
};
