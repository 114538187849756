import { authTokenState, loginState, userAtom } from 'atoms/authState';
import { useAxiosState } from 'atoms/axiosState';
import { WinsAdminAxios, queryClient, setAuth } from 'lib/ajax/axios';
import { tokenStorage, userStorage } from 'lib/userStorage';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Token } from 'services/auth/model';
import { Role, UserType } from 'services/schema';
import { User } from 'services/user/model';
import { UserReadWithDetail } from 'swagger/data-contracts';

export const useAuth = () => {
  const setUserState = useSetRecoilState(userAtom);
  const setTokenState = useSetRecoilState(authTokenState);
  const [axiosState, setAxiosState] = useAxiosState();

  useSyncAuthToken();

  const authorize = (token: Token) => {
    setAuth(queryClient, token);


    // todo: react-query를 사용하도록 바꾸기.
    const newAxios = Object.assign({}, axiosState, {
      accessToken: token.accessToken,
      refreshToken: token.refreshToken,
      tokenType: token.tokenType,
    }) as WinsAdminAxios;
    
    setAxiosState(newAxios);

    setTokenState({
      accessToken: token.accessToken,
      refreshToken: token.refreshToken,
      tokenType: token.tokenType,
    });
  };
  const userSelf = useCallback((user: User) => {
    userStorage.set(user);
    setUserState(user);
  }, [setUserState]);



  const navigate = useNavigate();
  const logout = () => {
    navigate('/login/', { replace: true });
    userStorage.clear();
    tokenStorage.clear();
    setUserState(null);
    setAxiosState(null);
    setTokenState(null);
  };

  const loggedIn = useRecoilValue(loginState);

  return {
    authorize,
    logout,
    userSelf,
    loggedIn,
  };
};

const useSyncAuthToken = () => {
  const [authToken, syncAuthToken] = useRecoilState(authTokenState);
  const [axiosState, setAxiosState] = useAxiosState();
  const token = tokenStorage.get();

  if (token?.accessToken) {
    if (token.accessToken !== authToken?.accessToken) {
      const newAxios = Object.assign({}, axiosState, {
        accessToken: token.accessToken,
        refreshToken: token.refreshToken,
        tokenType: token.tokenType,
      }) as WinsAdminAxios;
      syncAuthToken(token);
      setAxiosState(newAxios);
    }
  }
};


export const parseUserSelf = (rawData: UserReadWithDetail): User => ({
  id: rawData.id,
  email: rawData.email,
  firstName: rawData.first_name,
  lastName: rawData.last_name,
  userType: rawData.user_type as UserType,
  role: rawData.role as Role,
  agreedToTos: rawData.agreed_to_tos,
  company: rawData.company,
})