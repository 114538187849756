import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import { ReactNode } from 'react';
import { Body1, Page2 } from '../Typography';
import { Button } from '../Button';
import { keyframes, styled } from 'theme/stitches.config';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const StyledOverlay = styled(AlertDialogPrimitive.Overlay, {
  backgroundColor: '$dim',
  position: 'fixed',
  zIndex: '$alertBackground',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'grid',
  placeItems: 'center',
  overflowY: 'auto',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
});

const StyledContent = styled(AlertDialogPrimitive.Content, {
  backgroundColor: 'white',
  borderRadius: 6,
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  position: 'fixed',
  zIndex: '$alertContent',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  maxHeight: '90vh',
  overflow: 'auto',
  padding: '32px 16px',
  '@media (prefers-reduced-motion: no-preference) ': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
  '&:focus': { outline: 'none' },
});

export const AlertDialogRoot = AlertDialogPrimitive.Root;
export const AlertDialogTrigger = AlertDialogPrimitive.Trigger;

const AlertDialogContent = ({ width, children, ...props }: { width?: number; children: ReactNode }) => {
  return (
    <AlertDialogPrimitive.Portal>
      <StyledOverlay />
      <StyledContent {...props} css={{ width }}>
        {children}
      </StyledContent>
    </AlertDialogPrimitive.Portal>
  );
};

const AlertDialogTitle = Page2;
const AlertDialogDescription = ({ children }: { children: ReactNode }) => (
  <Body1 css={{ margin: '24px 0' }} as='div'>
    {children}
  </Body1>
);
const AlertDialogAction = AlertDialogPrimitive.Action;
const AlertDialogCancel = AlertDialogPrimitive.Cancel;

const ActionBox = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 16,
  gap: 8,
});
type Props = {
  title: string;
  width?: number;
  message?: string;
  children?: ReactNode;
  hideCancel?: boolean;

  actionLabel?: string;
  onAction?: () => void;
  cancelLabel?: string;
  onCancel?: () => void;
};
export const AlertDialog = ({
  title,
  message,
  children,
  width,
  actionLabel = 'Confirm',
  cancelLabel = 'Cancel',
  onAction,
  onCancel,
  hideCancel,
  ...props
}: Props) => {
  return (
    <AlertDialogContent width={width}>
      <AlertDialogTitle>{title}</AlertDialogTitle>
      <AlertDialogDescription>{message}</AlertDialogDescription>
      {children && <div>{children}</div>}
      <ActionBox>
        {!hideCancel && (
          <AlertDialogAction asChild onClick={onCancel}>
            <Button variant='outlined'>{cancelLabel}</Button>
          </AlertDialogAction>
        )}

        {onAction && (
          <AlertDialogCancel asChild onClick={onAction}>
            <Button variant='contained'>{actionLabel}</Button>
          </AlertDialogCancel>
        )}
      </ActionBox>
    </AlertDialogContent>
  );
};
