import LogoutIcon from 'assets/icons/logout.svg';
import { useUserState } from 'atoms/authState';
import { GnbMenu, Logo } from 'components/common/Typography';
import { useAuth } from 'hooks/useAuth';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { WINS_ROUTER } from 'router/config';
import { styled } from 'theme/stitches.config';
import { DRAWER_ID, useDrawerState } from '../Drawer';
import LogoImage from 'assets/images/logo.png';
import { notificactionAtom } from 'atoms/notifications';
import { useRecoilValue } from 'recoil';
import { useMarkNotificationAsReadApiV1NotificationsNotificationIdReadMutation } from 'services/user/queries';
const Container = styled('nav', {
  backgroundColor: '$primary',
  width: 128,
  height: '100%',
  zIndex: 200,
});
const LogoBox = styled('a', {
  display: 'inline-block',
  margin: '32px 0',
  padding: '32px',
});
const PageMenuBox = styled('div', {
  marginTop: 4,
  height: 352, // 88*4
  a: {
    textDecoration: 'none',
    '&.active div': {
      backgroundColor: '$gnbHover',
    },
  },
  div: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 88,
    padding: '12px 32px',
  },
  img: {
    display: 'block',
    marginBottom: 4,
  },
});
const SystemMenuBox = styled('div', {
  marginTop: 248,
  textAlign: 'center',
  a: {
    display: 'inline-block',
    '& + &': {
      marginTop: 16,
    },
  },
  div: {
    margin: '0  auto',
    padding: 8,
    width: 112,
    height: 32,
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    marginRight: 4,
  },
});

const LineTalkMenu = styled('div', {
  cursor: 'pointer',
  position: 'relative',
  variants: {
    hasNew: {
      true: {
        '&:after': {
          content: '""',
          position: 'absolute',
          width: 8,
          height: 8,
          borderRadius: '50%',
          backgroundColor: 'red',
          right: 32,
          top: 8,
        },
      },
    },
  },
  defaultVariants: {
    hasNew: true,
  },
});

export const NavBar = () => {
  const info = useUserState();
  const Pages = useMemo(() => WINS_ROUTER.filter(r => r.type === 'PAGE'), []);
  const System = useMemo(() => WINS_ROUTER.filter(r => r.type === 'SYSTEM'), []);

  const noti = useRecoilValue(notificactionAtom);
  const readAll = noti.notifications.filter(n => !n.is_read).length === 0;

  const { toggleDrawer } = useDrawerState();
  const readNotiApi = useMarkNotificationAsReadApiV1NotificationsNotificationIdReadMutation();
  const toggleLineTalk = async () => {
    if (noti.totalCount > 0) {
      const notiIds = noti.notifications.filter(n => !n.is_read);
      for (const noti of notiIds) {
        await readNotiApi.mutateAsync(noti.id);
      }
    }
    toggleDrawer(DRAWER_ID.LINE_TALK);
  };

  return (
    <Container>
      <LogoBox>
        <Logo>
          <img src={LogoImage} alt='WINZ logo' />
        </Logo>
      </LogoBox>
      <PageMenuBox>
        {Pages.map(({ navigationLabel, icon, location, allowedRoles }) => {
          if (allowedRoles === 'ANY' || allowedRoles.includes(info?.role!)) {
            if (location === 'linetalk') {
              return (
                <LineTalkMenu key={location} onClick={toggleLineTalk} hasNew={!readAll}>
                  <img src={icon} alt={navigationLabel} />
                  <GnbMenu>{navigationLabel}</GnbMenu>
                </LineTalkMenu>
              );
            } else {
              return (
                <NavLink key={location} to={`/${location}`}>
                  <div>
                    <img src={icon} alt={navigationLabel} />
                    <GnbMenu>{navigationLabel}</GnbMenu>
                  </div>
                </NavLink>
              );
            }
          } else {
            return <div key={location} />;
          }
        })}
      </PageMenuBox>
      <SystemMenuBox>
        {System.map(({ navigationLabel, icon, location, allowedRoles }) => {
          if (allowedRoles === 'ANY' || allowedRoles.includes(info?.role!)) {
            return (
              <NavLink key={location} to={`/${location}`}>
                <div>
                  <img src={icon} alt={navigationLabel} />
                  <GnbMenu>{navigationLabel}</GnbMenu>
                </div>
              </NavLink>
            );
          } else {
            return <div key={location} />;
          }
        })}
        <Logout />
      </SystemMenuBox>
    </Container>
  );
};

const Logout = () => {
  const { logout } = useAuth();

  const handleClick = () => {
    logout();
  };
  return (
    <a href='/login' onClick={handleClick}>
      <div>
        <img src={LogoutIcon} alt='Logout' />
        <GnbMenu>Logout</GnbMenu>
      </div>
    </a>
  );
};
