import { ErrorFallback } from 'components/common/Toast/Fallback';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useLocation } from 'react-router-dom';
import { styled } from 'theme/stitches.config';
import LineTalk from 'views/LineTalk';
import { NavBar } from './NavBar';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { notificactionAtom } from 'atoms/notifications';
import { AlertDialog, AlertDialogRoot } from '../Alert';
import { Body1, Body2 } from '../Typography';
import {
  useAgreeToTosSelfApiV1UsersSelfTosAgreedMutation,
  useGetUserSelfApiV1UsersSelfQuery,
  useListNotificationsByUserApiV1UsersSelfNotificationsQuery,
} from 'services/user/queries';

const Container = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  main: {
    flex: 1,
  },
});
export const Layout = () => {
  const location = useLocation();
  useUserNoti();

  const { isUserAgreed, agree } = useTosAgreement();
  const [openAlert, setOpenAlert] = useState(false);

  return (
    <Container>
      <NavBar />
      <main style={{ position: 'relative' }}>
        <ErrorBoundary FallbackComponent={ErrorFallback} resetKeys={[location]}>
          <AlertDialogRoot open={!isUserAgreed} onOpenChange={setOpenAlert}>
            <LineTalk />
            <Outlet />
            <AlertDialog
              title='ONrails Mooring Line Management Service Terms of Use'
              width={740}
              onAction={() => agree()}
              actionLabel='Agree'
              hideCancel
            >
              <TOS />
            </AlertDialog>
          </AlertDialogRoot>
        </ErrorBoundary>
      </main>
    </Container>
  );
};

// page

export const PageContainer = styled('div', {
  width: '100%',
  height: '100%',
});

export const PageHeader = styled('header', {
  width: '100%',
  height: '144px',
  padding: '32px 32px 24px 32px',
  // borderBottom: '1px solid $gray900',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
});
export const PageContent = styled('div', {});

// table

const useUserNoti = () => {
  const notiApi = useListNotificationsByUserApiV1UsersSelfNotificationsQuery({ limit: 50, offset: 0 });

  const updateNotifications = useSetRecoilState(notificactionAtom);

  useEffect(() => {
    if (notiApi.status === 'success') {
      updateNotifications({
        totalCount: notiApi.data.total_count,
        notifications: notiApi.data.notifications,
      });
    }
  }, [notiApi.data?.notifications, notiApi.data?.total_count, notiApi.status, updateNotifications]);
};

const useTosAgreement = () => {
  const selfApi = useGetUserSelfApiV1UsersSelfQuery();
  const isUserAgreed = useMemo(() => {
    if (selfApi.status === 'success') return selfApi.data.agreed_to_tos > 0;
    else return true;
  }, [selfApi.data, selfApi.status]);

  const tosAgreeApi = useAgreeToTosSelfApiV1UsersSelfTosAgreedMutation();
  const agree = useCallback(async () => {
    await tosAgreeApi.mutateAsync();
  }, [tosAgreeApi]);

  useEffect(() => {
    if (tosAgreeApi.status === 'success') window.location.reload();
  }, [tosAgreeApi.status]);

  return {
    isUserAgreed,
    agree,
    loading: tosAgreeApi.isPending,
  };
};

const Paragraph = styled('div', {
  marginBottom: '24px',
});
const TOS = () => {
  return (
    <div>
      <Paragraph>
        <Body1 weight='bold'>1. Service Description</Body1>
        <br />
        <Body2>1.1 ONrails provides a global online platform for the management of mooring lines for ships.</Body2>
        <br />
        <Body2>1.2 Through this service, subscribers can register and manage the ship's drawings and ropes.</Body2>
        <br />
      </Paragraph>
      <Paragraph>
        <Body1 weight='bold'>2. Effectiveness and Modification of Terms of Use</Body1>
        <br />
        <Body2>2.1 These terms come into effect from the date of agreement and apply when using the service.</Body2>
        <br />
        <Body2>
          2.2 ONrails may modify these terms due to service improvement or changes in regulations, and the modified
          terms take immediate effect.
        </Body2>

        <br />
      </Paragraph>

      <Paragraph>
        <Body1 weight='bold'>3. Service Usage and Member Information</Body1>
        <br />
        <Body2>
          3.1 Subscribers need to register to use the service, and provided information must be accurate and up-to-date.
        </Body2>
        <br />
        <Body2>
          3.2 Members are responsible for managing their accounts and passwords and taking measures to prevent
          unauthorized use.
        </Body2>
        <br />
      </Paragraph>
      <Paragraph>
        <Body1 weight='bold'>4. Contract Termination and Refund Policy</Body1>
        <br />
        <Body2>
          4.1 If a subscriber wishes to terminate the contract, they must provide written notice at least 30 days in
          advance.
        </Body2>
        <br />
        <Body2>
          4.2 Upon termination, subscribers may not receive a refund for part or all of the subscription fee already
          paid.
        </Body2>
        <br />
      </Paragraph>
      <Paragraph>
        <Body1 weight='bold'>5. Service Usage Regulations</Body1>
        <br />
        <Body2>
          5.1 Subscribers must comply with relevant laws, regulations, and ethical norms when using the service.
        </Body2>
        <br />
        <Body2>
          5.2 ONrails can change some or all features without prior notice for service improvement or maintenance.
        </Body2>
        <br />
        <Body2>
          5.3 Subscribers should refrain from the following actions: - Using the service for illegal purposes or causing
          harm to other users - Unauthorized access to ONrails systems or attempting hacking - Illegitimate use of
          others' personal information
        </Body2>
        <br />
      </Paragraph>
      <Paragraph>
        <Body1 weight='bold'>6. Legal Disputes and Copyright Provisions</Body1>
        <br />
        <Body2>6.1 Legal disputes between the parties will be resolved according to the laws of South Korea.</Body2>
        <br />
        <Body2>
          6.2 All rights and copyrights to ship drawings and ropes registered in the ONrails service belong to the
          subscribing user.
        </Body2>
        <br />
      </Paragraph>
      <Paragraph>
        <Body1 weight='bold'>7. Registered Information and Copyright</Body1>
        <br />
        <Body2>
          7.1 Copyright for ship drawings and ropes registered in the ONrails service belongs to the user who registered
          the information.
        </Body2>
        <br />
        <Body2>
          7.2 ONrails does not commercially use registered information and does not provide it to third parties without
          user consent.
        </Body2>
        <br />
      </Paragraph>
      <Paragraph>
        <Body1 weight='bold'>8. Logo Usage Agreemen</Body1>
        <br />
        <Body2>
          8.1 ONrails may use the logo of the registered user's company within the service for mutual promotion and
          marketing purposes.
        </Body2>
        <br />
        <Body2>
          8.2 Details regarding logo usage will be determined through written agreement between the parties.
        </Body2>
        <br />
      </Paragraph>
      <Paragraph>
        <Body1 weight='bold'>9. Personal Information Protection Clause</Body1>
        <br />
        <Body2>
          9.1 ONrails securely manages subscribers' personal information, adhering to relevant laws and regulations.
        </Body2>
        <br />
        <Body2>
          9.2 Accurate information about the collection, processing, and storage of personal information can be found in
          ONrails' Privacy Policy.
        </Body2>
        <br />
      </Paragraph>
      <Paragraph>
        <Body1 weight='bold'>10. Miscellaneous Provisions </Body1>
        <br />
        <Body2>
          10.1 Matters not specified in these terms will be governed by the relevant laws of each country and region.
        </Body2>
        <br />
        <Body2>
          10.2 Disputes between the parties will be resolved through agreement; if an agreement cannot be reached, the
          Seoul Central District Court will have jurisdiction.
        </Body2>
      </Paragraph>
    </div>
  );
};
