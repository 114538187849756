import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from 'theme/stitches.config';
import { Body1, Body2, Page1 } from '../Typography';

const Container = styled('div', {
  padding: '120px',
  'div + div': {
    marginTop: '32px',
  },
  a: {
    color: '$buttonSubmit',
    textDecoration: 'underline',
  },
});
const Button = styled('button', {
  all: 'unset',
  outline: 'none',
  backgroundColor: '$buttonSubmit',
  color: '$white',
  padding: '8px 16px',
  textAlign: 'center',
  boxSizing: 'border-box',
});

export function ErrorFallback({
  error,
  buttonLabel,
  resetErrorBoundary,
}: {
  error?: Error;
  buttonLabel?: string;
  resetErrorBoundary?: (...args: Array<unknown>) => void;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const mailToParams = {
    subject: '[WINS-ADMIN] Technical issue report',
    body: `This mail containing automatically generated logs from the client due to technical problems.%0D%0A%0D%0A
*** URL: ${location.pathname}%0D%0A
---- Technical info ----%0D%0A
*** userAgent: ${navigator.userAgent} %0D%0A
*** query: ${location.search || '(n/a)'}%0D%0A
*** hash: ${location.hash || '(n/a)'}%0D%0A
*** language: ${navigator.languages || navigator.language || '(n/a)'}%0D%0A
*** state: ${location.state ? Object.keys(location.state as Record<string, string>).join(', ') : '(n/a)'}%0D%0A
*** message: ${JSON.stringify(error?.message, null, 1)}%0D%0A
*** error stack: %0D%0A
${
  error?.stack
    ?.split('\n')
    .map(s => s.replace(/^\s+|\s$/g, ''))
    .join(' > ') || '(n/a)'
}%0D%0A%0D%0A
-------------------------%0D%0A%0D%0A%0D%0A%0D%0A
Please describe the details of the situation here.
`,
  };
  if (process.env.NODE_ENV === 'development') {
    console.debug(mailToParams.body);
  }

  const handleClick = () => {
    if(resetErrorBoundary) resetErrorBoundary();
    navigate(-1);
  }


  return (
    <Container>
      <div>
        <Page1>Something went wrong! :-(</Page1>
      </div>
      <div>
        <Body1 weight='medium'>
          If this message continues to appear, please <Mailto {...mailToParams}>contact us</Mailto>!
        </Body1>
      </div>
      <div>
        <Button onClick={handleClick}>
          <Body2 weight='regular'>{buttonLabel || 'Go back'}</Body2>
        </Button>
      </div>
    </Container>
  );
}

type Props = {
  subject: string;
  body: string;
  children: React.ReactNode;
};

const Email = 'admin@dsr.com';
const Mailto: React.FC<Props> = ({ subject, body, children }) => {
  return <a href={`mailto:${Email}?subject=${subject || ''}&body=${body || ''}`}>{children}</a>;
};
